import { isNumber, omitBy } from 'lodash';

const internalsPrefix = '--';

const getLabelQueryParamKey = (name: string) => `${name}${internalsPrefix}lbl`;

/**
 * Removes keys for empty query param values
 * @param queryParams
 */
export const sanitizeQueryParams = (queryParams: { [key: string]: any }) =>
  omitBy(queryParams, (value) => !isNumber(value) && !value);

/**
 * Builds query string based on passed params
 * @param search
 * @param initialValue
 * @param skipExisted
 */
export const buildQueryString = (
  search: { [key: string]: string | number | boolean },
  initialValue: string = '',
  skipExisted: boolean = false,
): string => {
  const searchParams: URLSearchParams = new URLSearchParams(initialValue);

  Object.keys(search).forEach((paramKey: string): void => {
    if (!(skipExisted && searchParams.has(paramKey))) {
      const value = search[paramKey].toString();

      if (!value) {
        searchParams.delete(paramKey);
      } else {
        searchParams.set(paramKey, value);
      }
    }
  });

  return `?${searchParams.toString()}`;
};

/**
 * Retrieves the query string param value
 * @param queryString
 * @param name
 */
export const readFromQueryString = <T extends string = string>(
  queryString: string,
  name: string,
): T | null => new URLSearchParams(queryString).get(name) as T;

/**
 * Retrieves the query string keys and values in the object shape
 * @param queryString
 * @param skipInternals
 */
export const readAllFromQueryParams = (
  queryString: string,
  skipInternals = true,
): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  new URLSearchParams(queryString).forEach((value: string, key: string) => {
    if (skipInternals && value.includes(internalsPrefix)) {
      return;
    }

    result[key] = value;
  });

  return result;
};


