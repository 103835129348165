import { call, put, takeEvery } from 'redux-saga/effects';
import DevicesActions from '../actions/devices/devicesActions.enum';
import GetDevicesListAction from '../actions/devices/interfaces/getDevicesListAction.interface';
import GetDevicesListResponseData from '../../api/endpoints/devices/interfaces/getDevicesListResponseData.interface';
import api from '../../api';
import {
  getDevicesListSuccess,
} from '../actions/devices';

function* getDevicesRequestWorker({ payload }: GetDevicesListAction) {
  try {
    const responseData: GetDevicesListResponseData = yield call(
      api.devices.getDevicesList,
      payload,
    );

    yield put(getDevicesListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }
}


export default function* devicesWatcher() {
  yield takeEvery<GetDevicesListAction>(
    DevicesActions.GET_DEVICES_LIST,
    getDevicesRequestWorker,
  );
}
