import ReduxAction from '../../actions/reduxAction.interface';
import UserActions from '../../actions/user/userActions.enum';
import GetUserSuccessAction from '../../actions/user/interfaces/getUserSuccessAction.interface';
import UserState from './userState.interface';
import User from '../../../interfaces/user.interface';
const initialState: UserState = {
  loading: false,
  user: {} as User,
  token: '',
};

export default function UserReducer(
  state = initialState,
  action: any,
): UserState {
  switch (action.type) {
    case UserActions.GET_USER_SUCCESS: {
      const { payload } = action as GetUserSuccessAction;
      return {
        ...state,
        user: payload,
      };
    }
    case UserActions.SET_TOKEN: {
      const { payload } = action as ReduxAction;
      return {
        ...state,
        token: payload?.token,
      };
    }
    default:
      return state;
  }
}
