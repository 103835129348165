import axios from 'axios';
import { API_URL } from '../../../constants/api';
import CollectionsRoutes from './enums/collectionsRoutes.enum';
import Collections from '../../../interfaces/collections.interface';

export default {
  /**
   * Retrieves the devices list
   * @param requestData
   */
  getCollectionsList: async (): Promise<Collections[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CollectionsRoutes.Collections}/-2`,
    });

    return data;
  },

  getCollectionByName: async (name: string): Promise<Collections> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CollectionsRoutes.Collections}/token/${name}`,
    });

    return data;
  },

  createCollection: async (
    collection: any,
    files: any,
  ): Promise<Collections> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${CollectionsRoutes.Collections}`,
      data: {
        collection,
        files,
      },
    });
    return data;
  },

  deleteCollection: async (id: number): Promise<Collections> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${CollectionsRoutes.Collections}/${id}`,
    });
    return data;
  },

  checkFile: async (file: any, onUploadProgress: any): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${CollectionsRoutes.Check}`,
      data: formData,
      onUploadProgress,
    });
    return data;
  },
  updateCollection: async (
    id: number,
    collection: Collections,
  ): Promise<Collections> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${CollectionsRoutes.Collections}/${id}`,
      data: collection,
    });
    return data;
  },
};
