import axios from 'axios';
import { API_URL } from '../../../constants/api';
import UsersRoutes from './enums/usersRoutes.enum';
import User from '../../../interfaces/user.interface';

export default {
  /**
   * Retrieves the user list
   */

  login: async (): Promise<User> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/me`,
    });
    return data;
  },

  getCurrentUser: async (): Promise<User> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/me`,
    });
    return data;
  },
};
