import GetCollectionsListSuccessAction from './interfaces/getCollectionsListSuccessAction.interface';
import GetCollectionsListAction from './interfaces/getCollectionsListAction.interface';
import CollectionsActions from './collectionsActions.enum';
import Collections from '../../../interfaces/collections.interface';

export const getCollectionsList = (): GetCollectionsListAction => ({
  type: CollectionsActions.GET_COLLECTIONS_LIST,
});

export const getCollectionsListSuccess = (
  payload: Collections[],
): GetCollectionsListSuccessAction => ({
  type: CollectionsActions.GET_COLLECTIONS_LIST_SUCCESS,
  payload,
});
