import React, { useEffect, useState } from 'react';
import styles from './addVote.module.scss';
import { Formik } from 'formik';
import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import api from '../../../api';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routing/config';
import * as Yup from 'yup';
import Toastify from 'toastify-js';
import Vote from '../../../interfaces/vote.interface';
import TextField from '@mui/material/TextField';
import FileInput from '../../../components/FileInput';
import defaultImage from '../../../assets/img/defaultImage.png';

const AddVoteSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

const AddVote = ({ history }: { history?: any }): JSX.Element => {
  const navigation = useNavigate();
  const vote = history?.location?.state?.vote;
  const [file, setFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const [image, setImage] = useState(vote?.image);

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setPercent(percentCompleted);
    console.log('percentCompleted', percentCompleted);
  };

  useEffect(() => {
    if (file) {
      api.votes.uploadFile(file, onUploadProgress).then((data) => {
        setImage(data.Location);
        console.log(data);
      });
    }
  }, [file]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Vote Management</div>
      <Formik
        initialValues={{
          name: vote?.name || '',
          filmOne: vote?.filmOne || '',
          yearOfFilmOne: vote?.yearOfFilmOne || '',
          actorsOfFilmOne: vote?.actorsOfFilmOne || '',
          filmTwo: vote?.filmTwo || '',
          yearOfFilmTwo: vote?.yearOfFilmTwo || '',
          actorsOfFilmTwo: vote?.actorsOfFilmTwo || '',
          description: vote?.description || '',
          startDate: vote?.startDate || '',
          endDate: vote?.endDate || '',
          image: vote?.image || '',
        }}
        validationSchema={AddVoteSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.image = image;
          if (vote) {
            api.votes
              .updateVote(vote.id, values)
              .then(() => {
                Toastify({
                  text: 'All data has been sucessfully saved.',
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#A9C335',
                  },
                }).showToast();
                navigation(routes.votesManagement.route);
              })
              .catch((err: string) => {
                Toastify({
                  // text: 'Please make sure that all indicated fields are being filled out.',
                  text: err,
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#C52F2F',
                  },
                }).showToast();
              });
          } else {
            api.votes
              .createVote(values as Vote)
              .then(() => {
                navigation(routes.votesManagement.route);
              })
              .catch((err: string) => {
                Toastify({
                  // text: 'Please make sure that all indicated fields are being filled out.',
                  text: err,
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#C52F2F',
                  },
                }).showToast();
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [wasSubmitting, setWasSubmitting] = useState(isSubmitting);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (
              !isSubmitting &&
              wasSubmitting &&
              JSON.stringify(errors) !== JSON.stringify({})
            ) {
              Toastify({
                text: 'Please lease make sure that all indicated fields are being filled out',
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top', // `top` or `bottom`
                position: 'right', // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                  background: '#C52F2F',
                },
              }).showToast();
            }
          }, [isSubmitting, wasSubmitting, errors, values]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setWasSubmitting(isSubmitting);
          }, [isSubmitting]);
          return (
            <>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>General</div>
              </div>
              <div className={styles.blockWrapper}>
                <Input
                  className={styles.inputWrapper}
                  errorText={errors.name}
                  errorTouched={touched.name}
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  title="Vote name"
                  required
                />
                <Textarea
                  value={values.description}
                  name="description"
                  onChange={handleChange}
                  title="Description"
                />

                <div className={styles.titleName}>Start Date</div>
                <TextField
                  id="startDate"
                  type="datetime-local"
                  value={values.startDate}
                  name="startDate"
                  onChange={handleChange}
                  sx={{
                    width: '333px',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    marginTop: 1,
                  }}
                  inputProps={{
                    style: {
                      padding: 5,
                    },
                  }}
                />

                <div className={styles.titleName}>End Date</div>
                <TextField
                  id="endDate"
                  type="datetime-local"
                  value={values.endDate}
                  name="endDate"
                  onChange={handleChange}
                  sx={{
                    width: '333px',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    marginTop: 1,
                  }}
                  inputProps={{
                    style: {
                      padding: 5,
                    },
                  }}
                />
              </div>

              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Film One</div>
              </div>

              <div className={styles.blockWrapper}>
                <Input
                  className={styles.inputWrapper}
                  value={values.filmOne}
                  name="filmOne"
                  onChange={handleChange}
                  title="Film One Name"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.yearOfFilmOne}
                  name="yearOfFilmOne"
                  onChange={handleChange}
                  title="Year"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.actorsOfFilmOne}
                  name="actorsOfFilmOne"
                  onChange={handleChange}
                  title="Actors"
                />
              </div>

              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Film Two</div>
              </div>
              <div className={styles.blockWrapper}>
                <Input
                  className={styles.inputWrapper}
                  value={values.filmTwo}
                  name="filmTwo"
                  onChange={handleChange}
                  title="Film Two Name"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.yearOfFilmTwo}
                  name="yearOfFilmTwo"
                  onChange={handleChange}
                  title="Year"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.actorsOfFilmTwo}
                  name="actorsOfFilmTwo"
                  onChange={handleChange}
                  title="Actors"
                />
              </div>

              <div className={styles.blockWrapper}>
                <FileInput
                  title="Upload Image"
                  setFile={setFile}
                  onChange={() => {}}
                  value=""
                />
                {percent > 0 && percent < 100 && `loading... ${percent}%`}
              </div>
              <img
                className={styles.image}
                src={!image ? defaultImage : image}
                alt="nft image"
              />
              <div className={styles.divider} />
              <div className={styles.buttonsWrapper}>
                <div className={styles.buttonWrapper}>
                  <Button size="medium" onClick={handleSubmit} type="danger">
                    ABORT
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button size="medium" onClick={handleSubmit} type="primary">
                    SAVE
                  </Button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddVote;
