enum DevicesActions {
  SET_DEVICES_LOADING = 'SET_DEVICES_LOADING',
  GET_DEVICES_LIST = 'GET_DEVICES_LIST',
  GET_DEVICES_LIST_SUCCESS = 'GET_DEVICES_LIST_SUCCESS',
  GET_ADMIN_DEVICES_LIST = 'GET_ADMIN_DEVICES_LIST',
  GET_ADMIN_DEVICES_LIST_SUCCESS = 'GET_ADMIN_DEVICES_LIST_SUCCESS',
  UPDATE_DEVICE = 'UPDATE_DEVICE',
  UPDATE_MULTIPLE_DEVICES = 'UPDATE_MULTIPLE_DEVICES',
  UPDATE_MULTIPLE_ADMIN_DEVICES = 'UPDATE_MULTIPLE_ADMIN_DEVICES',
  CREATE_DEVICE = 'CREATE_DEVICE',
}

export default DevicesActions;
