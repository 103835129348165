import React, { Component, useEffect, useState } from 'react';
import styles from './addCollection.module.scss';
import Input from '../../../components/Input';
import { Formik } from 'formik';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import Check from '../../../components/Check';
import FileInput from '../../../components/FileInput';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import InputArray from '../../../components/InputArray';
import CustomArray from '../../../components/CustomArray';
import api from '../../../api';
import routes from '../../../routing/config';
import { useNavigate, useParams } from 'react-router-dom';
import Toastify from 'toastify-js';
import ArrayAspect from './arraAspectRatio';
import * as Yup from 'yup';
import NativePickers from './dataTime';
import { useDispatch, useSelector } from 'react-redux';
import GlobalState from '../../../store/reducers/globalState.interface';
import { getCollectionsList } from '../../../store/actions/collections';

const blockchainOptions = [
  { value: 'ROPSTEN', label: 'ETH TEST CHAIN' },
  { value: 'ETH', label: 'ETH' },
  { value: 'POLYGON', label: 'POLYGON' },
];
const publicAddressOptions = [
  {
    value: '0xad5Ddb857BB26E0985394b097D41331698675A13',
    label: '0xad5Ddb857BB26E0985394b097D41331698675A13',
  },
  {
    value: '0xam5Ddb857BB26E0985394b097D41331698675A13',
    label: '0xam5Ddb857BB26E0985394b097D41331698675A13',
  },
];
const storageOptions = [
  {
    value: 'ipfs',
    label: 'IPFS',
  },
  {
    value: 'arweave',
    label: 'Arweave',
  },
  {
    value: 'text',
    label: 'Text in NFT Code',
  },
];
const upgradeabilityOptions = [
  {
    value: 'no',
    label: 'Non-Upgradeable Royalty Structure',
  },
  {
    value: 'yes',
    label: 'Upgradeable Royalty Structure',
  },
];
const sensitiveContent = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

const frameRate = [
  {
    value: 24,
    label: '24',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 29.97,
    label: '29.97',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 59.94,
    label: '59.94',
  },
  {
    value: 60,
    label: '60',
  },
];

const AddCollection: any = ({ history }: { history?: any }): JSX.Element => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [activeStorage, setActiveStorage] = useState('');
  const [file, setFile] = useState(null);
  const [nftCounter, setNFTCounter] = useState(0);
  const [percent, setPercent] = useState(0);
  const [nftList, setNFTList] = useState([]);
  const [activeUpgradeability, setActiveUpgradeability] = useState('');
  const [openseaStatus, setOpenseaStatus] = useState(false);
  const [rareableStatus, setRareableStatus] = useState(false);
  let { id } = useParams<{ id: string }>();
  const collections = useSelector(
    (state: GlobalState) => state.collectionsState.collections,
  );
  let collection: any = collections.find(x => x.id.toString() === id) || {};
  const handleClose = () => {
    navigation(routes.collectionsList.route);
  };
  const AddCollectionSchema = Yup.object().shape({
    tokenName: Yup.string().required().trim(),
    blockchain: Yup.string().required(),
    // openseaUrl: Yup.string()
    //   .matches(
    //     /^((http|https|ftp):\/\/)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i,
    //   )
    //   .required(),
    // openseaSensetive: Yup.string().required(),
    // openseaDescription: Yup.string().required(),
    // metatagsName: Yup.string().required(),
    // metatagsSecondTitle: Yup.string().required(),
    // metatagsGenre: Yup.string().required(),
    // metatagsCountry: Yup.string().required(),
    // licenceHolder: Yup.string().required(),
    // productionCompany: Yup.string().required(),
    // licenseRights: Yup.string().required(),
    // filmLength: Yup.number().positive().required(),
    // director: Yup.string().required(),
    // producer: Yup.string().required(),
    // writer: Yup.string().required(),
    // directorOfPhotography: Yup.string().required(),
    // editor: Yup.string().required(),
    // music: Yup.string().required(),
    // location: Yup.array().of(Yup.string().trim().required()).required(),
    // weather: Yup.array().of(Yup.string().trim().required()).required(),
    // action: Yup.array().of(Yup.string().trim().required()).required(),
    // costumes: Yup.array().of(Yup.string().trim().required()).required(),
    // makeUp: Yup.array().of(Yup.string().trim().required()).required(),
    // animals: Yup.array().of(Yup.string().trim().required()).required(),
    // creatures: Yup.array().of(Yup.string().trim().required()).required(),
    // dressing: Yup.array().of(Yup.string().trim().required()).required(),
    // propsItems: Yup.array().of(Yup.string().trim().required()).required(),
    // actingProps: Yup.array().of(Yup.string().trim().required()).required(),
    // actingVehicle: Yup.array().of(Yup.string().trim().required()).required(),
    // signs: Yup.array().of(Yup.string().trim().required()).required(),
    // rareableUrl: Yup.string().required(),
    // rareableDescription: Yup.string().required(),
    // rareableFee: Yup.string().required(),
    // rareableSensetive: Yup.string().required(),
    numberOfUploadedFiles: Yup.number(),
    // frameRate: Yup.string().required(),
    nftDefaultName: Yup.string().required(),
    collectionName: Yup.string().required(),
    // nftPrice: Yup.string()
    //   .matches(/^-?\d+\.?\d*$/)
    //   .required(),
  });

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setPercent(percentCompleted);
    console.log('percentCompleted', percentCompleted);
  };
  useEffect(() => {
    if (file) {
      api.collections.checkFile(file, onUploadProgress).then((data) => {
        setNFTCounter(data.length);
        setNFTList(data);
      });
    }
  }, [file]);
  useEffect(() => {
    dispatch(getCollectionsList());
  }, []);
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Collection Settings</div>
      <Formik
        enableReinitialize
        initialValues={{
          tokenName: collection.tokenName || '',
          collectionName: collection.collectionName || '',
          nftDefaultName: collection.nftDefaultName || '',
          blockchain: collection.blockchain || '',
          releasingWallet: collection.releasingWallet || '',
          storage: collection.storage || '',
          upgradeable: collection.upgradeable || '',
          openseaActivated: collection.openseaActivated || false,
          openseaLogoImage: collection.openseaLogoImage || '', //not added
          openseaFeaturedImage: collection.openseaFeaturedImage || '', //not added
          openseaBannerImage: collection.openseaBannerImage || '', //not added
          openseaUrl: collection.openseaUrl || '',
          openseaDescription: collection.openseaDescription || '',
          openseaFee: collection.openseaFee || 0,
          openseaSensetive: collection.openseaSensetive || '',
          rareableActivated: collection.rareableActivated || false,
          rareableUrl: collection.rareableUrl || '',
          rareableDescription: collection.rareableDescription || '',
          rareableFee: collection.rareableFee || 0,
          rareableSensetive: collection.rareableSensetive || '',
          metatagsName: collection.metatagsName || '',
          metatagsSecondTitle: collection.metatagsSecondTitle || '',
          metatagsGenre: collection.metatagsGenre || '',
          metatagsCountry: collection.metatagsCountry || '',
          licenceHolder: collection.licenceHolder || '',
          productionCompany: collection.productionCompany || '',
          licenseRights: collection.licenseRights || '',
          masterLanguage: collection.masterLanguage || '',
          filmLength: collection.filmLength || '',
          mediaFormat: collection.mediaFormat || '',
          director: collection.director || '',
          producer: collection.producer || '',
          writer: collection.writer || '',
          directorOfPhotography: collection.directorOfPhotography || '',
          editor: collection.editor || '',
          music: collection.music || '',
          artDirector: collection.artDirector || '',
          castMember: collection.castMember || [], // NOT ADDED
          location: collection.location || [],
          weather: collection.weather || [],
          action: collection.action || [],
          costumes: collection.costumes || [],
          makeUp: collection.makeUp || [],
          animals: collection.animals || [],
          creatures: collection.creatures || [],
          dressing: collection.dressing || [], // add on server
          propsItems: collection.propsItems || [],
          actingProps: collection.actingProps || [],
          actingVehicle: collection.actingVehicle || [],
          actingWeapons: collection.actingWeapons || [],
          signs: collection.signs || [],
          specials: collection.specials,

          rareableLogoImage: collection.rareableLogoImage || '', //not added
          rareableFeaturedImage: collection.rareableFeaturedImage || '', //not added
          rareableBannerImage: collection.rareableBannerImage || '', //not added

          keyFramesPath: collection.keyFramesPath || '', //delete from server
          frameRate: collection.frameRate || '',
          aspectRatio: collection.aspectRatio || '',
          fileList: collection.fileList || [], // generate on server
          numberUploadedFiles: collection.numberUploadedFiles || '', // generate on server
          clipsKeyFramesPath: collection.clipsKeyFramesPath || '', //delete from server
          clipsFileList: collection.clipsFileList || [], // generate on server
          clipsNumberUploadedFiles: collection.clipsNumberUploadedFiles || '', // generate on server
          openseaToken: collection.openseaToken || '', // ?
          rareableToken: collection.rareableToken || '', // ?
          numberOfUploadedFiles: collection.numberOfUploadedFiles || 0,
          nftPrice: collection.nftPrice || '',
        }}
        validationSchema={AddCollectionSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log('nftCounter', nftCounter);
          console.log('collection.nfts.length', typeof collection.nfts);
          if (
            (!(nftCounter > 0) && collection.nfts === undefined) ||
            collection.nfts === 0
          ) {
            Toastify({
              text: 'Please make sure that all indicated fields are being filled out.',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top', // `top` or `bottom`
              position: 'right', // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                background: '#C52F2F',
              },
            }).showToast();
            return;
          }
          if (id) {
            api.collections
              .updateCollection(collection.id, values as any)
              .then(() => {
                Toastify({
                  text: 'All data has been sucessfully saved.',
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#A9C335',
                  },
                }).showToast();
                navigation(routes.collectionsList.route);
              })
              .catch((err: string) => {
                Toastify({
                  // text: 'Please make sure that all indicated fields are being filled out.',
                  text: err,
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#C52F2F',
                  },
                }).showToast();
              });
          } else {
            api.collections
              .createCollection(values, nftList)
              .then(() => {
                Toastify({
                  text: 'All data has been sucessfully saved.',
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#A9C335',
                  },
                }).showToast();
                navigation(routes.collectionsList.route);
              })
              .catch((err) => {
                Toastify({
                  text: err,
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#C52F2F',
                  },
                }).showToast();
              });
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [wasSubmitting, setWasSubmitting] = useState(isSubmitting);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (
              !isSubmitting &&
              wasSubmitting &&
              JSON.stringify(errors) !== JSON.stringify({})
            ) {
              Toastify({
                text: 'Please lease make sure that all indicated fields are being filled out',
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top', // `top` or `bottom`
                position: 'right', // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                  background: '#C52F2F',
                },
              }).showToast();
            }
          }, [isSubmitting, wasSubmitting, errors, values]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setWasSubmitting(isSubmitting);
          }, [isSubmitting]);
          return (
            <>
              {!id && (
                <div>
                  <div className={styles.divider} />
                  <div className={styles.subTitle}>
                    Items (NFT) Upload • MovieShots
                  </div>
                </div>
              )}
              {!id && (
                <div className={styles.blockWrapper}>
                  <FileInput
                    title="Upload Key Frames (Movie Shots) as .zip ﬁle"
                    setFile={setFile}
                    onChange={() => {}}
                    value=""
                    accept=".zip"
                  />
                  {percent > 0 && percent < 100 && `loading... ${percent}%`}
                  <Select
                    value={values.frameRate}
                    title="Frame Rate"
                    onChange={(e: any) => {
                      setFieldValue('frameRate', e.value);
                    }}
                    errorText={errors.frameRate}
                    errorTouched={touched.frameRate}
                    options={frameRate}
                    required
                  />
                  <Select
                    value={values.aspectRatio}
                    title="Aspect Ratio"
                    onChange={(e: any) => {
                      setFieldValue('aspectRatio', e.value);
                    }}
                    options={ArrayAspect}
                  />
                  <div className={styles.greyText}>
                    File list
                    {percent > 0 && !(nftList.length > 0) && ` generating...`}
                  </div>
                  <div className={styles.fileCounter}>
                    {nftList.map((info: { name: string }) => (
                      <>
                        {info.name}
                        <br />
                      </>
                    ))}
                  </div>
                  {/*<div className={styles.greyText}>*/}
                  {/*  Number of uploaded Files*/}
                  {/*</div>*/}
                  <Input
                    className={styles.fileCounter}
                    value={nftCounter}
                    errorText={!(nftCounter > 0)}
                    errorTouched={touched.numberOfUploadedFiles}
                    name="numberOfUploadedFiles"
                    onChange={(e: any) => {
                      setFieldValue('numberOfUploadedFiles', e.value);
                    }}
                    required
                    title="Number of uploaded Files"
                  />
                  <Input
                    className={styles.inputWrapper}
                    value={values.nftPrice}
                    name="nftPrice"
                    onChange={handleChange}
                    title="NFT Price"
                  />
                </div>
              )}
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Name</div>
              </div>
              <div className={styles.blockWrapper}>
                {!id && (
                  <Input
                    className={styles.inputWrapper}
                    value={values.tokenName}
                    errorText={errors.tokenName}
                    errorTouched={touched.tokenName}
                    name="tokenName"
                    onChange={handleChange}
                    required
                    title="Token Name"
                  />
                )}
                <Input
                  className={styles.inputWrapper}
                  value={values.collectionName}
                  name="collectionName"
                  errorText={errors.collectionName}
                  errorTouched={touched.collectionName}
                  required
                  onChange={handleChange}
                  title="Collection Name"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.nftPrice}
                  name="nftPrice"
                  onChange={handleChange}
                  title="NFT Price"
                />

                <Input
                  value={values.nftDefaultName}
                  name="nftDefaultName"
                  errorText={errors.nftDefaultName}
                  errorTouched={touched.nftDefaultName}
                  required
                  onChange={handleChange}
                  title="NFT Default Name (numbering added to the right)"
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Blockchain</div>
              </div>
              <div className={styles.blockWrapper}>
                <Select
                  value={values.blockchain}
                  title="Blockchain"
                  errorText={errors.blockchain}
                  errorTouched={touched.blockchain}
                  required
                  onChange={(e: any) => {
                    setFieldValue('blockchain', e.value);
                  }}
                  options={blockchainOptions}
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Releasing Wallet</div>
              </div>
              <div className={styles.blockWrapper}>
                <Select
                  value={values.releasingWallet}
                  title="Public Address"
                  onChange={(e: any) => {
                    setFieldValue('releasingWallet', e.value);
                  }}
                  options={publicAddressOptions}
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Storage</div>
              </div>
              <div className={styles.blockWrapper}>
                <Checkbox
                  value={storageOptions}
                  title="Media Storage & Metadata Storage"
                  active={activeStorage}
                  onChange={(value: any) => {
                    setActiveStorage(value);
                    setFieldValue('storage', value);
                  }}
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Upgradeability</div>
              </div>
              <div className={styles.blockWrapper}>
                <Checkbox
                  value={upgradeabilityOptions}
                  active={activeUpgradeability}
                  onChange={(value: any) => {
                    setActiveUpgradeability(value);
                    setFieldValue('upgradeable', value);
                  }}
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Marketplace OpenSea</div>
              </div>
              <div className={styles.blockWrapper}>
                <Check
                  value="Activated"
                  active={openseaStatus}
                  onChange={(status: boolean) => {
                    setOpenseaStatus(status);
                    setFieldValue('openseaActivated', status);
                  }}
                />
                {/*<FileInput title="Logo Image" onChange={() => {}} value="" />*/}
                {/*<FileInput*/}
                {/*  title="Featured Image"*/}
                {/*  onChange={() => {}}*/}
                {/*  value=""*/}
                {/*/>*/}
                {/*<FileInput title="Banner Image" onChange={() => {}} value="" />*/}
                <Input
                  value={values.openseaUrl}
                  name="openseaUrl"
                  // errorText={errors.openseaUrl}
                  // errorTouched={touched.openseaUrl}
                  onChange={handleChange}
                  title="URL (customize your URL on OpenSea)"
                  // required
                />
                <Textarea
                  value={values.openseaDescription}
                  name="openseaDescription"
                  // errorText={errors.openseaDescription}
                  // errorTouched={touched.openseaDescription}
                  onChange={handleChange}
                  title="Description"
                  // required
                />
                <Input
                  value={values.openseaFee}
                  name="openseaFee"
                  onChange={handleChange}
                  title="Percentage Fee"
                  type="number"
                  required
                />
                <Select
                  value={values.openseaSensetive}
                  title="Explicit & Sensitive Content"
                  onChange={(e: any) => {
                    setFieldValue('openseaSensetive', e.value);
                  }}
                  // errorText={errors.openseaSensetive}
                  // errorTouched={touched.openseaSensetive}
                  options={sensitiveContent}
                  // required
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Marketplace Rareable</div>
              </div>
              <div className={styles.blockWrapper}>
                <Check
                  value="Activated"
                  active={rareableStatus}
                  onChange={(status: boolean) => {
                    setRareableStatus(status);
                    setFieldValue('rareableActivated', status);
                  }}
                />
                {/*<FileInput title="Logo Image" onChange={() => {}} value="" />*/}
                {/*<FileInput*/}
                {/*  title="Featured Image"*/}
                {/*  onChange={() => {}}*/}
                {/*  value=""*/}
                {/*/>*/}
                {/*<FileInput title="Banner Image" onChange={() => {}} value="" />*/}
                <Input
                  value={values.rareableUrl}
                  name="rareableUrl"
                  // errorText={errors.rareableUrl}
                  // errorTouched={touched.rareableUrl}
                  onChange={handleChange}
                  title="URL (customize your URL on Rareable)"
                  // required
                />
                <Textarea
                  value={values.rareableDescription}
                  name="rareableDescription"
                  // errorText={errors.rareableDescription}
                  // errorTouched={touched.rareableDescription}
                  onChange={handleChange}
                  title="Description"
                  // required
                />
                <Input
                  value={values.rareableFee}
                  name="rareableFee"
                  onChange={handleChange}
                  title="Percentage Fee"
                  type="number"
                  required
                />

                <Select
                  value={values.rareableSensetive}
                  title="Explicit & Sensitive Content"
                  // errorText={errors.rareableSensetive}
                  // errorTouched={touched.rareableSensetive}
                  onChange={(e: any) => {
                    setFieldValue('', e.value);
                  }}
                  options={sensitiveContent}
                  // required
                />
              </div>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Collection Metatags</div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.grey}>General</div>
                </div>
                <div>
                  <Input
                    value={values.metatagsName}
                    name="metatagsName"
                    // errorText={errors.metatagsName}
                    // errorTouched={touched.metatagsName}
                    onChange={handleChange}
                    title="Name"
                    // required
                  />
                  <Input
                    value={values.metatagsSecondTitle}
                    name="metatagsSecondTitle"
                    // errorText={errors.metatagsSecondTitle}
                    // errorTouched={touched.metatagsSecondTitle}
                    onChange={handleChange}
                    title="Second Title"
                    // required
                  />
                  <Input
                    value={values.metatagsGenre}
                    name="metatagsGenre"
                    // errorText={errors.metatagsGenre}
                    // errorTouched={touched.metatagsGenre}
                    onChange={handleChange}
                    title="Genre"
                    // required
                  />
                  <Input
                    value={values.metatagsCountry}
                    name="metatagsCountry"
                    // errorText={errors.metatagsCountry}
                    // errorTouched={touched.metatagsCountry}
                    onChange={handleChange}
                    title="Country"
                    // required
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.grey}>Legal</div>
                </div>
                <div>
                  <Input
                    value={values.licenceHolder}
                    name="licenceHolder"
                    // errorText={errors.licenceHolder}
                    // errorTouched={touched.licenceHolder}
                    onChange={handleChange}
                    title="License Holder"
                    // required
                  />
                  <Input
                    value={values.productionCompany}
                    name="productionCompany"
                    // errorText={errors.productionCompany}
                    // errorTouched={touched.productionCompany}
                    onChange={handleChange}
                    title="Production Company"
                    // required
                  />
                  <Input
                    value={values.licenseRights}
                    name="licenseRights"
                    // errorText={errors.licenseRights}
                    // errorTouched={touched.licenseRights}
                    onChange={handleChange}
                    title="License Rights"
                    // required
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.grey}>Technical Speciﬁcation</div>
                </div>
                <div>
                  <Input
                    value={values.masterLanguage}
                    name="masterLanguage"
                    onChange={handleChange}
                    title="Master Language"
                  />
                  <Input
                    value={values.filmLength}
                    name="filmLength"
                    type="number"
                    // errorText={errors.filmLength}
                    // errorTouched={touched.filmLength}
                    onChange={handleChange}
                    title="Film Length (minutes)"
                    // required
                  />
                  <Input
                    value={values.mediaFormat}
                    name="mediaFormat"
                    onChange={handleChange}
                    title="Media Format"
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.grey}>Crew</div>
                </div>
                <div>
                  <Input
                    value={values.director}
                    name="director"
                    // errorText={errors.director}
                    // errorTouched={touched.director}
                    onChange={handleChange}
                    title="Director"
                    // required
                  />
                  <Input
                    value={values.producer}
                    name="producer"
                    // errorText={errors.producer}
                    // errorTouched={touched.producer}
                    onChange={handleChange}
                    title="Producer"
                    // required
                  />
                  <Input
                    value={values.writer}
                    name="writer"
                    // errorText={errors.writer}
                    // errorTouched={touched.writer}
                    onChange={handleChange}
                    title="Writer"
                    // required
                  />
                  <Input
                    value={values.directorOfPhotography}
                    name="directorOfPhotography"
                    // errorText={errors.directorOfPhotography}
                    // errorTouched={touched.directorOfPhotography}
                    onChange={handleChange}
                    title="Director Of Photography"
                    // required
                  />
                  <Input
                    value={values.editor}
                    name="editor"
                    // errorText={errors.editor}
                    // errorTouched={touched.editor}
                    onChange={handleChange}
                    title="Editor"
                    // required
                  />
                  <Input
                    value={values.music}
                    name="music"
                    // errorText={errors.music}
                    // errorTouched={touched.music}
                    onChange={handleChange}
                    title="Music"
                    // required
                  />
                  <Input
                    value={values.artDirector}
                    name="artDirector"
                    onChange={handleChange}
                    title="Art Director"
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.greyForArray}>Cast Members</div>
                </div>
                <div>
                  <CustomArray
                    autocomplete={[]}
                    firstTitle="Actor"
                    secondTitle="Character"
                    value={values.castMember}
                    onChange={(inputs: any) => {
                      setFieldValue('castMember', inputs);
                    }}
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.greyForArray}>Set Properties</div>
                </div>
                <div>
                  <InputArray
                    autocomplete={[]}
                    title="Location"
                    // errorText={errors.location}
                    // errorTouched={touched.location}
                    value={values.location}
                    onChange={(inputs: any) => {
                      setFieldValue('location', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Weather"
                    // errorText={errors.weather}
                    // errorTouched={touched.weather}
                    value={values.weather}
                    onChange={(inputs: any) => {
                      setFieldValue('weather', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Action"
                    // errorText={errors.action}
                    // errorTouched={touched.action}
                    value={values.action}
                    onChange={(inputs: any) => {
                      setFieldValue('action', inputs);
                    }}
                    // required
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.greyForArray}>Look</div>
                </div>
                <div>
                  <InputArray
                    autocomplete={[]}
                    title="Costumes"
                    // errorText={errors.costumes}
                    // errorTouched={touched.costumes}
                    value={values.costumes}
                    onChange={(inputs: any) => {
                      setFieldValue('costumes', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Make-Up & Hair"
                    // errorText={errors.makeUp}
                    // errorTouched={touched.makeUp}
                    value={values.makeUp}
                    onChange={(inputs: any) => {
                      setFieldValue('makeUp', inputs);
                    }}
                    // required
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.greyForArray}>Alive</div>
                </div>
                <div>
                  <InputArray
                    autocomplete={[]}
                    title="Animals"
                    // errorText={errors.animals}
                    // errorTouched={touched.animals}
                    value={values.animals}
                    onChange={(inputs: any) => {
                      setFieldValue('animals', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Creatures"
                    // errorText={errors.creatures}
                    // errorTouched={touched.creatures}
                    value={values.creatures}
                    onChange={(inputs: any) => {
                      setFieldValue('creatures', inputs);
                    }}
                    // required
                  />
                </div>
              </div>

              <div className={styles.generalGreyColumn}>
                <div>
                  <div className={styles.greyForArray}>Props</div>
                </div>
                <div>
                  <InputArray
                    autocomplete={[]}
                    title="Set"
                    // errorText={errors.dressing}
                    // errorTouched={touched.dressing}
                    value={values.dressing}
                    onChange={(inputs: any) => {
                      setFieldValue('dressing', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Props Items"
                    // errorText={errors.propsItems}
                    // errorTouched={touched.propsItems}
                    value={values.propsItems}
                    onChange={(inputs: any) => {
                      setFieldValue('propsItems', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Acting Props"
                    // errorText={errors.actingProps}
                    // errorTouched={touched.actingProps}
                    value={values.actingProps}
                    onChange={(inputs: any) => {
                      setFieldValue('actingProps', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Acting Vehicle"
                    // errorText={errors.actingVehicle}
                    // errorTouched={touched.actingVehicle}
                    value={values.actingVehicle}
                    onChange={(inputs: any) => {
                      setFieldValue('actingVehicle', inputs);
                    }}
                    // required
                  />
                  {/*<InputArray*/}
                  {/*  autocomplete={[]}*/}
                  {/*  title="Acting Weapons"*/}
                  {/*  value={values.actingWeapons}*/}
                  {/*  onChange={(inputs: any) => {*/}
                  {/*    setFieldValue('actingWeapons', inputs);*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <InputArray
                    autocomplete={[]}
                    title="Signages"
                    // errorText={errors.signs}
                    // errorTouched={touched.signs}
                    value={values.signs}
                    onChange={(inputs: any) => {
                      setFieldValue('signs', inputs);
                    }}
                    // required
                  />
                  <InputArray
                    autocomplete={[]}
                    title="Special"
                    value={values.specials}
                    onChange={(inputs: any) => {
                      setFieldValue('specials', inputs);
                    }}
                  />
                </div>
              </div>
              {/*<div className={styles.title}>Minting settings</div>*/}
              {/*<div className={styles.divider} />*/}
              {/*<div className={styles.generalGreyColumn}>*/}
              {/*  <div className={styles.subTitle}>Pre Minting Period(PM)</div>*/}
              {/*  <div>*/}
              {/*    <span className={styles.greyForArray}>*/}
              {/*      Pre Minting Period(PM)*/}
              {/*    </span>*/}
              {/*    <div className={styles.sizeTime}>*/}
              {/*      <div>*/}
              {/*        <div className={styles.greyForArray}>From</div>*/}
              {/*        <NativePickers />*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <div className={styles.styleTo}>*/}
              {/*          <div className={styles.greyForArray}>To</div>*/}
              {/*          <NativePickers />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className={styles.distanceBetweenBlocks}></div>*/}
              {/*  <div className={styles.styleTo}>*/}
              {/*    <span className={styles.greyText}>*/}
              {/*      White List/Free Mint(WL/FM)*/}
              {/*    </span>*/}
              {/*    <div className={styles.sizeTime}>*/}
              {/*      <div>*/}
              {/*        <div className={styles.greyForArray}>From</div>*/}
              {/*        <NativePickers />*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <div className={styles.styleTo}>*/}
              {/*          <div className={styles.greyForArray}>To</div>*/}
              {/*          <NativePickers />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className={styles.blockWrapperMinting}>*/}
              {/*  <div className={styles.rowInputs}>*/}
              {/*    <FileInput*/}
              {/*      title="choose path to upload Excel()"*/}
              {/*      setFile={setFile}*/}
              {/*      onChange={() => {}}*/}
              {/*      value=""*/}
              {/*      accept=".zip"*/}
              {/*    />*/}
              {/*    <div className={styles.wordsExplanations}>*/}
              {/*      Upload the CV table where Pre-Mint, Free-Mint and*/}
              {/*      Whitelisted accounts are defined. To update the table please*/}
              {/*      re- upload the Itatest one.*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className={styles.divider} />*/}
              {/*<div className={styles.generalGreyColumn}>*/}
              {/*  <div className={styles.subTitleTime}>Public Mint</div>*/}
              {/*  <div className={styles.sizeTime}>*/}
              {/*    <div>*/}
              {/*      <div className={styles.greyForArray}>From</div>*/}
              {/*      <NativePickers />*/}
              {/*    </div>*/}

              {/*    <div className={styles.styleTo}>*/}
              {/*      <div className={styles.greyForArray}>To</div>*/}
              {/*      <NativePickers />*/}
              {/*    </div>*/}
              {/*    <div className={styles.wordsExplanations}>*/}
              {/*      This period defines when public minting is available. All*/}
              {/*      times are UTC.*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*</div>*/}

              {/*// <div>*/}
              {/*  <div className={styles.divider} />*/}
              {/*  <div className={styles.subTitle}>Upload Clips</div>*/}
              {/*</div>*/}
              {/*<div className={styles.blockWrapper}>*/}
              {/*  <FileInput*/}
              {/*    title="Upload Key Frames (MovieClips) as .zip ﬁle"*/}
              {/*    onChange={() => {}}*/}
              {/*    value=""*/}
              {/*    accept=".zip"*/}
              {/*  />*/}
              {/*  <div className={styles.greyText}>File list</div>*/}
              {/*  <div className={styles.fileCounter}></div>*/}
              {/*  <div className={styles.greyText}>Number of uploaded Files</div>*/}
              {/*  <div className={styles.fileCounter}>0</div>*/}
              {/*</div>*/}

              <div className={styles.buttonsWrapper}>
                <div className={styles.buttonWrapper}>
                  <Button size="medium" onClick={handleClose} type="danger">
                    ABORT
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button size="medium" onClick={handleSubmit} type="primary">
                    SAVE
                  </Button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddCollection;
