import { combineReducers } from 'redux';

import DevicesReducer from './devices';
import UsersReducer from './users';
import UserReducer from './user';
import CollectionsReducer from './collections';

export const rootReducer = combineReducers({
  devices: DevicesReducer,
  users: UsersReducer,
  user: UserReducer,
  collectionsState: CollectionsReducer,
});
