import React, { Component, useState } from 'react';
import styles from './financialLog.module.scss';

const FinancialLog: React.FC = (): JSX.Element => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Financial Overview</div>
    </div>
  );
};
export default FinancialLog;
