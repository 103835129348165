import React from 'react';
import styles from './ckeck.module.scss';
import ActiveCheckbox from '../../assets/img/active-check.svg';
import InactiveCheckbox from '../../assets/img/inactive-checkbox.svg';

export default ({
  title,
  onChange,
  value,
  active,
  className,
  name,
  onFocus,
}: {
  title?: string;
  active: boolean;
  onChange: any;
  value: any;
  name?: string;
  className?: any;
  onFocus?: any;
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div
        onClick={() => {
          if (onFocus) {
            onFocus();
          }
          onChange(!active);
        }}
        className={styles.checkboxWrapper}
      >
        <img src={active ? ActiveCheckbox : InactiveCheckbox} alt="inactive" />
        <div className={styles.value}>{value}</div>
      </div>
    </div>
  );
};
