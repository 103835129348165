import { formatObject } from './formatObject';
import { formatCustomString } from './formatCustomString';

export const normalizeCollections = (collections: any) => {
  console.log('collections', collections);
  return collections.map((collection: any) => {
    return {
      ...collection,
      costumes: '',
      location: '',
      action: '',
      propsItems: '',
      actingProps: '',
      actingVehicle: '',
      makeUp: '',
      signs: '',
      specials: '',
      dressing: '',
      nfts: collection.nfts.map((nft: any) => {
        return {
          ...nft,
          costumes: nft.costumes?.join(', '),
          location: nft.location?.join(', '),
          action: nft.action?.join(', '),
          propsItems: nft.propsItems?.join(', '),
          actingProps: nft.actingProps?.join(', '),
          actingVehicle: nft.actingVehicle?.join(', '),
          makeUp: nft.makeUp?.join(', '),
          signs: nft.signs?.join(', '),
          specials: nft.specials?.join(', '),
          dressing: nft.dressing?.join(', '),
          castMembers: formatObject(nft.castMembers),
          shotSize: formatCustomString(nft.shotSize),
          newName: `${collection.collectionName} #${nft.counter}`,
        };
      }),
    };
  });
};
