import React, { Component, useEffect, useState } from 'react';

export const arraAspectRatio = [
  {
    value: '.461538:1',
    label: '.461538:1',
  },
  {
    value: '.5625:1',
    label: '.5625:1',
  },
  {
    value: '01:01',
    label: '01:01',
  },
  {
    value: '1.2:1',
    label: '1.2:1',
  },
  {
    value: '1.25:1',
    label: '1.25:1',
  },
  {
    value: '1.3:1',
    label: '1.3:1',
  },
  {
    value: '1.375:1',
    label: '1.375:1',
  },
  {
    value: '1.43:1',
    label: '1.43:1',
  },
  {
    value: '1.5:1',
    label: '1.5:1',
  },
  {
    value: '1.56:1',
    label: '1.56:1',
  },
  {
    value: '1.6:1',
    label: '1.6:1',
  },
  {
    value: '1.6180:1',
    label: '1.6180:1',
  },
  {
    value: '1.6:1',
    label: '1.6:1',
  },
  {
    value: '1.7:1',
    label: '1.7:1',
  },
  {
    value: '1.85:1',
    label: '1.85:1',
  },
  {
    value: '1.9:1',
    label: '1.9:1',
  },
  {
    value: '02:01',
    label: '02:01',
  },
  {
    value: '2.2:1',
    label: '2.2:1',
  },
  {
    value: '2.370:1',
    label: '2.370:1',
  },
  {
    value: '2.35:1',
    label: '2.35:1',
  },
  {
    value: '2.39:1',
    label: '2.39:1',
  },
  {
    value: '2.4:1',
    label: '2.4:1',
  },
  {
    value: '2.414:1',
    label: '2.414:1',
  },
  {
    value: '2.76:1',
    label: '2.76:1',
  },
  {
    value: '3.5:1',
    label: '3.5:1',
  },
  {
    value: '3.6:1',
    label: '3.6:1',
  },
];
export default arraAspectRatio;
