import axios from 'axios';
import { store } from '../../store';
export default () => {
  axios.interceptors.request.use(async (config) => {
    const token = store.getState().user?.token || '';

    return {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });
};
