import axios from 'axios';
import { API_URL } from '../../../constants/api';
import Vote from '../../../interfaces/vote.interface';
import CollectionsRoutes from '../collections/enums/collectionsRoutes.enum';

export default {
  /**
   * Retrieves the votes list
   */
  getVotesList: async (): Promise<Vote[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/votes`,
    });

    return data;
  },

  createVote: async (vote: Vote): Promise<Vote> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/votes`,
      data: vote,
    });
    return data;
  },

  deleteVote: async (id: number): Promise<Vote> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/votes/${id}`,
    });
    return data;
  },

  updateVote: async (id: number, vote: Vote): Promise<Vote> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/votes/${id}`,
      data: vote,
    });
    return data;
  },

  uploadFile: async (file: any, onUploadProgress: any): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/votes/upload`,
      data: formData,
      onUploadProgress,
    });
    return data;
  },
};
