import React, { Component, useState } from 'react';
import styles from './media.module.scss';

const Media: React.FC = (): JSX.Element => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Media Editor</div>
    </div>
  );
};
export default Media;
