import React from 'react';
import Modal from 'react-modal';
import styles from './modalDialog.module.scss';
import Button from '../Button';

export default function ModalDialog({ children, onSubmit, text }: any) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const submitModal = () => {
    onSubmit();
    setIsOpen(false);
  };
  return (
    <div>
      <div onClick={openModal}>{children}</div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            position: 'relative',
            background: '#292a2f',
            width: 300,
            height: 200,
            overflow: 'auto',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            boxShadow: '4px 4px 4px #111111',
            border: '0px',
          },
          overlay: {
            display: 'flex',
            backgroundColor: 'rgba(0, 0, 0, 0.40)',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999,
          },
        }}
        contentLabel="Example Modal"
      >
        <h2 className={styles.textColorYellow}>Attention</h2>
        <div className={styles.textColorWhite}>{text}</div>
        <div className={styles.buttonStyle}>
          <Button size="medium" type="danger" onClick={closeModal}>
            ABORT
          </Button>
          <Button size="medium" type="primary" onClick={submitModal}>
            YES
          </Button>
        </div>
      </Modal>
    </div>
  );
}
