import axios from 'axios';
import { API_URL } from '../../../constants/api';
import UsersRoutes from './enums/usersRoutes.enum';
import User from '../../../interfaces/user.interface';
import UserAccessLog from '../../../interfaces/userAccessLog.interface';

export default {
  /**
   * Retrieves the user list
   */
  getUsersList: async (): Promise<User[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}`,
    });

    return data;
  },
  getUsersAccessList: async (): Promise<UserAccessLog[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.AccessLog}`,
    });
    return data;
  },

  createUser: async (user: User): Promise<User> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${UsersRoutes.Users}`,
      data: user,
    });
    return data;
  },

  deleteUser: async (id: number): Promise<User> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${UsersRoutes.Users}/${id}`,
    });
    return data;
  },

  getNonce: async (wallet: string): Promise<string> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/nonce/${wallet}`,
    });
    return data;
  },

  getAuthToken: async (nonce: string, signature: string): Promise<string> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${UsersRoutes.Login}`,
      data: {
        nonce,
        signature,
      },
    });
    return data;
  },
  updateUser: async (id: number, user: User): Promise<User> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${UsersRoutes.Users}/${id}`,
      data: user,
    });
    return data;
  },
};
