import React from 'react';
import styles from './input.module.scss';

export default ({
  title,
  onChange,
  value,
  className,
  name,
  type,
  errorText,
  errorTouched,
  required,
  onFocus,
}: {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  name?: string;
  type?: string;
  className?: any;
  errorText?: any;
  errorTouched?: any;
  required?: boolean;
  onFocus?: any;
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
        <span className={styles.bulletPoint}>{required ? '●' : ''}</span>
      </div>
      <input
        type={type ? type : 'text'}
        className={errorText && errorTouched ? styles.inputError : styles.input}
        name={name || ''}
        value={value}
        onFocus={onFocus}
        onChange={onChange}
      />
    </div>
  );
};
