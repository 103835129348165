import React, { useEffect, useState } from 'react';
import styles from './inputArray.module.scss';
import stylesError from './inputArrayError.module.scss';
import Button from '../Button';
import Autosuggest from 'react-autosuggest';

export default ({
  title,
  onChange,
  value,
  className,
  name,
  type,
  autocomplete,
  errorText,
  errorTouched,
  required,
  onFocus,
}: {
  title: string;
  onChange: any;
  value: string[];
  name?: string;
  type?: string;
  className?: any;
  autocomplete: string[];
  errorText?: any;
  errorTouched?: any;
  required?: boolean;
  onFocus?: any;
}): JSX.Element => {
  const [inputs, setInputs] = useState(value?.length ? value : ['']);
  const [suggestions, setSuggestions] = useState(autocomplete);
  useEffect(() => {
    onChange(inputs);
  }, [inputs]);
  const getSuggestions = (value: string) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? autocomplete
      : autocomplete
          .sort()
          .filter((option) => option.toLowerCase().includes(inputValue));
  };

  const getSuggestionValue = (suggestion: string) => suggestion;

  const renderSuggestion = (suggestion: string) => <div>{suggestion}</div>;

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
        <span className={styles.bulletPoint}>{required ? '●' : ''}</span>
      </div>
      {inputs.map((inp, i) => {
        return (
          <div className={styles.inputWrapper}>
            <div className={styles.index}>#{i + 1}</div>

            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              theme={errorText && errorTouched ? stylesError : styles}
              inputProps={{
                value: inputs[i],
                onChange: (e, { newValue }) => {
                  setInputs((oldArray) => {
                    if (onFocus) {
                      onFocus();
                    }
                    oldArray[i] = newValue;
                    return [...oldArray];
                  });
                },
              }}
            />
            <div className={styles.buttonWrapper}>
              <Button
                type="primary"
                size="medium"
                onClick={() => {
                  if (i > 0) {
                    setInputs(inputs.filter((item, index) => index !== i));
                  }
                }}
              >
                -
              </Button>
            </div>
            {i + 1 === inputs.length && (
              <div className={styles.buttonWrapper}>
                <Button
                  type="primary"
                  size="medium"
                  onClick={() => {
                    setInputs((oldArray) => [...oldArray, '']);
                  }}
                >
                  +
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
