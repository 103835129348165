import React from 'react';
import styles from './ckeckbox.module.scss';
import ActiveCheckbox from '../../assets/img/active-checkbox.svg';
import InactiveCheckbox from '../../assets/img/inactive-checkbox.svg';

export default ({
  title,
  onChange,
  value,
  active,
  className,
  name,
}: {
  title?: string;
  active: string;
  onChange: any;
  value: any;
  name?: string;
  className?: any;
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      {value.map((val: any) => {
        return (
          <div
            onClick={() => {
              onChange(val.value);
            }}
            className={styles.checkboxWrapper}
          >
            <img
              src={active === val.value ? ActiveCheckbox : InactiveCheckbox}
              alt="inactive"
            />
            <div className={styles.value}>{val.label}</div>
          </div>
        );
      })}
    </div>
  );
};
