import React, { useCallback, useState, useEffect } from 'react';
import styles from './collectionsList.module.scss';
import {
  useTable,
  useExpanded,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  useRowSelect,
  useBlockLayout,
} from 'react-table';
import Arrow from '../../../assets/icons/caret.svg';
import Trash from '../../../assets/icons/trash.svg';
import Order from '../../../assets/icons/order.svg';
import Edit from '../../../assets/icons/edit.svg';
import Search from '../../../assets/icons/search.svg';
import Clear from '../../../assets/icons/clear.svg';
import Active from '../../../assets/img/active-select.svg';
import Inactive from '../../../assets/img/inactive-select.svg';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routing/config';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionsList } from '../../../store/actions/collections';
import GlobalState from '../../../store/reducers/globalState.interface';
import ModalDialog from '../../../components/ModalDialog';
import api from '../../../api';
import { FixedSizeList } from 'react-window';

// @ts-ignore
import { convertArrayToCSV } from 'convert-array-to-csv';
import { formatObject } from '../../../utils/formatObject';
import { formatCustomString } from '../../../utils/formatCustomString';
import { normalizeCollections } from '../../../utils/normalizeCollections';

const CollectionsList: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const collections = useSelector(
    (state: GlobalState) => state.collectionsState.collections,
  );

  const [toched, setToch] = useState(0);
  function globalFilter(rows: [], columnIds: [], searchQuery: string) {
    if (!searchQuery) return rows;
    const rowMatches = (row: any): boolean =>
      Object.values(row.values).some((rowValue) => {
        return (
          typeof rowValue === 'string' &&
          rowValue.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }) || row.subRows.some(rowMatches);
    return (
      rows
        .filter(rowMatches)
        //@ts-ignore
        .map((row) => ({ ...row, subRows: row.subRows }))
    );
  }

  const customSorting = (a: any, b: any, id: any) => {
    if (a.original[id] > b.original[id]) return -1;
    if (b.original[id] > a.original[id]) return 1;
  };

  const deleteCollection = async (id: number) => {
    await api.collections.deleteCollection(id);
    dispatch(getCollectionsList());
  };

  const deleteNFT = async (id: number) => {
    await api.nfts.deleteNFT(id);
    dispatch(getCollectionsList());
  };
  const data = React.useMemo(
    () => normalizeCollections(collections),
    [collections],
  );
  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <img src={Arrow} className={styles.expanded} alt="expanded" />
              ) : (
                <img
                  src={Arrow}
                  className={styles.nonExpanded}
                  alt="non-expanded"
                />
              )}
            </span>
          ) : null,
        width: 20,
      },

      {
        Header: '',
        accessor: 'edit',
        width: 30,
      },
      { Header: 'Thumbnail', accessor: 'image', width: 100 },
      {
        Header: 'Token Name',
        accessor: 'tokenName',
        width: 200,
        style: {
          marginLeft: ' -12px',
        },
      },
      {
        Header: 'NFT name',
        accessor: 'newName',
        width: 270,
      },
      {
        Header: 'Screentime (s)',
        accessor: 'screenTime',
        width: 110,
        style: {
          marginLeft: ' -2px',
        },
      },
      {
        Header: 'Time Code',
        accessor: 'timecode',
        width: 120,
      },
      {
        Header: 'Technical Properties',
        style: { color: '#E5BE1A', marginLeft: '-15px' },
        columns: [
          {
            Header: 'Shot Size',
            accessor: 'shotSize',
            width: 77,
          },
          {
            Header: 'Transition',
            accessor: 'transition',
            width: 115,
          },
          {
            Header: 'Special',
            accessor: 'specials',
            width: 250,
          },
        ],
      },
      {
        Header: 'Set Properties',
        style: { color: '#E5BE1A' },
        columns: [
          {
            Header: 'Location',
            accessor: 'location',
          },
          {
            Header: 'Action',
            accessor: 'action',
          },
        ],
      },
      {
        Header: 'Cast',
        style: { color: '#E5BE1A' },
        columns: [
          {
            Header: 'Character',
            accessor: 'castMembers',
            width: 300,
          },
        ],
      },
      {
        Header: 'Look',
        style: { color: '#E5BE1A' },
        columns: [
          {
            Header: 'Costumes',
            accessor: 'costumes',
            width: 410,
            style: {
              marginLeft: '-2px',
            },
          },
          {
            Header: 'MakeUp & Hair',
            accessor: 'makeUp',
          },
        ],
      },
      {
        Header: 'Props',
        style: { color: '#E5BE1A' },
        columns: [
          {
            Header: 'Set',
            accessor: 'dressing',
            width: 200,
            style: {
              marginLeft: ' 5px',
            },
          },
          {
            Header: 'Props Items',
            accessor: 'propsItems',
            style: {
              marginLeft: ' -1px',
            },
          },
          { Header: 'Acting Props', accessor: 'actingProps', width: 250 },
          { Header: 'Acting Vehicle', accessor: 'actingVehicle', width: 200 },
          {
            Header: 'Signages',
            accessor: 'signs',
            width: 250,
            style: {
              marginLeft: ' -2px',
            },
          },
          {
            Header: 'Status',
            accessor: 'editingStatus',
            width: 60,
          },
          {
            Header: 'Minted',
            accessor: 'minted',
            width: 64,
            sortType: customSorting,
          },
        ],
      },
      {
        Header: '',
        accessor: 'delete',
        width: 40,
      },

      {
        Header: '',
        accessor: 'market',
        width: 40,
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(getCollectionsList());
  }, []);

  const getSubRows = useCallback((row) => {
    if (row.nfts) {
      return [...row.nfts];
    }
    return [];
  }, []);
  const tableInstance = useTable(
    {
      //@ts-ignore
      columns,
      data,
      getSubRows,
      globalFilter,
      initialState: {
        //@ts-ignore
        sortBy: [
          {
            id: 'tokenName',
            desc: false,
          },
        ],
      },
    },

    useFilters,
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    allColumns,
  }: any = tableInstance;

  const [array, setArray] = useState([
    { name: 'Technical Properties', show: true },
    { name: 'Set Properties', show: true },
    { name: 'Cast', show: true },
    { name: 'Look', show: true },
    { name: 'Props', show: true },
  ]);
  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }: any) => {
    const [value, setValue] = React.useState(globalFilter);
    const search = useAsyncDebounce(() => {
      setGlobalFilter(value || undefined);
    }, 200);
    const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        search();
      }
    };
    try {
      setToch(rows[0].subRows.length);
    } catch (err) {
      setToch(0);
    }

    return (
      <div className={styles.searchContainer}>
        <input
          className={styles.searchInput}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value || undefined);
          }}
          onKeyDown={handleKeyDown}
          placeholder={`Search`}
        />
        <div onClick={() => setGlobalFilter('')} className={styles.clearButton}>
          <img className={styles.searchIcon} src={Clear} alt="clear" />
        </div>
        <div onClick={search} className={styles.searchButton}>
          <img className={styles.searchIcon} src={Search} alt="search" />
        </div>
      </div>
    );
  };

  const CustomSelect = ({
    selected,
    children,
    key,
    onClick,
  }: {
    selected: boolean;
    children: string;
    key: string;
    onClick: any;
  }) => {
    return (
      <div
        onClick={() => {
          onClick(selected);
        }}
        key={key}
        className={styles.customSelectWrapper}
      >
        <img
          src={selected ? Active : Inactive}
          className={styles.customSelect}
          alt="expanded"
        />
        {children}
      </div>
    );
  };
  const CustomCell = ({ cell }: any) => {
    if (typeof cell.value === 'boolean') {
      return (
        <td {...cell.getCellProps()}>
          {cell.value ? (
            <span className={styles.center}>
              <span className={styles.activeRound} />
            </span>
          ) : (
            <span className={styles.center}>
              <span className={styles.inActiveRound} />
            </span>
          )}
        </td>
      );
    }

    if (cell.column.id === 'image') {
      if (cell.value) {
        return (
          <td {...cell.getCellProps()}>
            <img
              src={cell.value}
              loading="lazy"
              alt="..."
              className={styles.imgWidth}
            />
          </td>
        );
      }
    }

    if (
      cell.column.id === 'delete' ||
      cell.column.id === 'market' ||
      cell.column.id === 'edit'
    ) {
      return (
        <td {...cell.getCellProps()} className={styles.imgColumn}>
          {cell.column.Header}
          {cell.column.id === 'delete' && (
            <ModalDialog
              text="Do you really want to delete this entry? This action is irrevocable."
              onSubmit={() => {
                if (cell?.row?.original?.nftDefaultName) {
                  deleteCollection(cell?.row?.original?.id);
                } else {
                  deleteNFT(cell?.row?.original?.id);
                }
              }}
            >
              <img style={{ cursor: 'pointer' }} src={Trash} />
            </ModalDialog>
          )}
          {cell.column.id === 'market' && (
            <img
              style={{ cursor: 'pointer' }}
              src={Order}
              onClick={() => {
                window.open(
                  `https://opensea.io/assets/ethereum/0xe4fdfd40df57ecd6cab7cbdaffa83582dca965dc/${
                    cell.row.original?.counter || ''
                  }`,
                );
              }}
            />
          )}
          {cell.column.id === 'edit' && (
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (
                  cell.row.original?.collectionName &&
                  (cell.row.original?.counter ||
                    cell.row.original?.counter == '0')
                ) {
                  history(
                    `${routes.editNFT.route}/${cell.row.original.collectionName}/${cell.row.original.counter}`,
                  );
                } else {
                  history(
                    `${routes.editCollection.route}/${cell?.row?.original?.id}`,
                    {
                      state: {
                        collection: cell?.row?.original,
                      },
                    },
                  );
                }
              }}
              src={Edit}
            />
          )}
        </td>
      );
    }
    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
  };
  const timeFormat = (second: number) => {
    let sec = second % 60;
    let minute = Math.floor(second / 60);
    if (minute > 10) {
      return minute + ':' + sec.toFixed(3);
    } else {
      return `0${minute + ':' + sec.toFixed(3)}`;
    }
  };

  const csvFromArrayOfObjects = () => {
    collections.map((collection: any) => {
      collection.nfts.map((nft: any) => {
        nft.castMembers = formatObject(nft.castMembers);
        nft.shotSize = formatCustomString(nft.shotSize);
        nft.timeOfDay = formatCustomString(nft.timeOfDay);
        let newTime = +nft.screenTime;
        nft.screenTime = timeFormat(newTime);
      });
      const csvFromArrayOfObjects = convertArrayToCSV(collection.nfts, {
        separator: ';',
      });
      let pom = document.createElement('a');
      let blob = new Blob([csvFromArrayOfObjects], {
        type: 'text/csv;charset=utf-8;',
      });
      let url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', `${collection.collectionName}.csv`);
      pom.click();
    });
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps({
            style,
          })}
          className={index % 2 === 0 ? styles.tableRow : styles.tableRowLight}
        >
          {row.cells.map((cell: any) => {
            return <CustomCell cell={cell} />;
          })}
        </tr>
      );
    },
    [prepareRow, rows],
  );

  return (
    <div>
      <div className={styles.headerBlock}>
        <div className={styles.title}>Collections & NFTs</div>
        <div className={styles.dFlex}>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              history(routes.addCollection.route);
            }}
          >
            + ADD COLLECTION
          </Button>
          <div className={styles.empty} />
          <Button type="primary" size="small" onClick={csvFromArrayOfObjects}>
            EXPORT CSV
          </Button>

          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className={styles.colorText}>
        {toched} of{' '}
        {collections.map((num) => {
          return num.nfts.length;
        })}
      </div>
      <div />
      <div className={styles.allColumnsWrapper}>
        <div className={styles.colorTextMovie}>MovieShots (NFT)</div>
        {array.map((group: any) => {
          return (
            <CustomSelect
              onClick={() => {
                let filteredColumns = [] as any[];

                if (group.name === 'Technical Properties') {
                  filteredColumns = allColumns.filter(
                    (column: any) =>
                      column.Header === 'Shot Size' ||
                      column.Header === 'Transition' ||
                      column.Header === 'Special',
                  );
                }

                if (group.name === 'Set Properties') {
                  filteredColumns = allColumns.filter(
                    (column: any) =>
                      column.Header === 'Location' ||
                      column.Header === 'Action',
                  );
                }
                if (group.name === 'Cast') {
                  filteredColumns = allColumns.filter(
                    (column: any) => column.Header === 'Character',
                  );
                }
                if (group.name === 'Look') {
                  filteredColumns = allColumns.filter(
                    (column: any) =>
                      column.Header === 'MakeUp & Hair' ||
                      column.Header === 'Costumes',
                  );
                }
                if (group.name === 'Props') {
                  filteredColumns = allColumns.filter(
                    (column: any) =>
                      column.Header === 'Set' ||
                      column.Header === 'Props Items' ||
                      column.Header === 'Acting Props' ||
                      column.Header === 'Acting Vehicle' ||
                      column.Header === 'Signages' ||
                      column.Header === 'Status' ||
                      column.Header === 'Minted',
                  );
                }

                filteredColumns.map((filteredColumn) => {
                  filteredColumn.toggleHidden();
                });
                group.show = !group.show;
                setArray(array);
              }}
              key={group}
              selected={group.show}
            >
              {group.name}
            </CustomSelect>
          );
        })}
      </div>

      <div className={styles.borderSpacingDiv}>
        <table className={styles.borderSpacing} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={styles.tableHeader}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    className={styles.tableColumnWrapper}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...column.getHeaderProps([
                      {
                        style: column.style,
                      },
                    ])}
                  >
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Arrow}
                            className={styles.arrowBottom}
                            alt="expanded"
                          />
                        ) : (
                          <img
                            src={Arrow}
                            className={styles.arrowTop}
                            alt="expanded"
                          />
                        )
                      ) : (
                        <span className={styles.emptyArrow} />
                      )}
                    </span>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <div {...getTableBodyProps()} className={styles.tableRows}>
            <FixedSizeList
              itemCount={rows.length}
              itemSize={100}
              width={3705}
              height={window.screen.height - 600}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </table>
      </div>
    </div>
  );
};
export default CollectionsList;
