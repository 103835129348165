import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login';
import AddCollection from '../../pages/Collections/AddCollection';
import CollectionsList from '../../pages/Collections/CollectionsList';
import config from '../config';
import SystemSettings from '../../pages/Settings/SystemSettings';
import UsersManagement from '../../pages/Settings/UsersManagement';
import SystemAccessLog from '../../pages/Logs/SystemAccessLog';
import MintingLog from '../../pages/Logs/MintingLog';
import FinancialLog from '../../pages/Logs/FinancialLog';
import Media from '../../pages/WebpageEditor/Media';
import Text from '../../pages/WebpageEditor/Text';
import EditNFT from '../../pages/Collections/EditNFT';
import AddUser from '../../pages/Settings/AddUser';
import VotesManagement from '../../pages/Votes/VotesManagement';
import AddVote from '../../pages/Votes/AddVote';

export default () => {
  return (
    <div>
      <Routes>
        {/*<Route exact path={config.login.route} component={Login} />*/}
        <Route path={config.addCollection.route} element={<AddCollection />} />
        <Route
          path={`${config.editCollection.route}/:id`}
          element={<AddCollection />}
        />
        <Route
          path={config.collectionsList.route}
          element={<CollectionsList />}
        />
        <Route
          path={config.systemSettings.route}
          element={<SystemSettings />}
        />

        <Route
          path={config.usersManagement.route}
          element={<UsersManagement />}
        />

        <Route
          path={config.systemAccessLog.route}
          element={<SystemAccessLog />}
        />
        <Route
          path={`${config.editNFT.route}/:collection/:id`}
          element={<EditNFT />}
        />
        <Route path={config.mintingLog.route} element={MintingLog} />
        <Route path={config.addUser.route} element={AddUser} />
        <Route
          path={config.votesManagement.route}
          element={<VotesManagement />}
        />
        <Route path={config.addVote.route} element={AddVote} />

        <Route path={config.financialLog.route} element={<FinancialLog />} />
        <Route path={config.textEditor.route} element={<Text />} />
        <Route path={config.mediaEditor.route} element={<Media />} />
      </Routes>
    </div>
  );
};
