import errorsInterceptor from './interceptors/errors.interceptor';
import authorizationInterceptor from './interceptors/authorization.interceptor';
import devices from './endpoints/devices';
import users from './endpoints/users';
import user from './endpoints/user';
import votes from './endpoints/votes';
import nfts from './endpoints/nfts';
import collections from './endpoints/collections';

errorsInterceptor();
authorizationInterceptor();

export default {
  devices,
  users,
  user,
  collections,
  nfts,
  votes,
};
