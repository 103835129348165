import React from 'react';
import styles from './select.module.scss';
import Select from 'react-select';
export default ({
  title,
  options,
  onChange,
  value,
  className,
  name,
  errorText,
  errorTouched,
  required,
  onFocus,
}: {
  title: string;
  onChange: any;
  value: any;
  options: any;
  name?: string;
  className?: any;
  errorText?: any;
  errorTouched?: any;
  required?: boolean;
  onFocus?: any;
}): JSX.Element => {
  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      color: 'black',
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: errorText && errorTouched ? '#C52F2F' : 'white',
      color: 'black',
      border: 0,
      borderRadius: 2,
    }),
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
        <span className={styles.bulletPoint}>{required ? '●' : ''}</span>
      </div>
      <Select
        onFocus={onFocus}
        onChange={onChange}
        value={options.filter((option: any) => option.value === value)}
        styles={customStyles}
        options={options}
      />
    </div>
  );
};
