import React from 'react';
import styles from './layout.module.scss';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

export default ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <div>
      <Header />
      <div className={styles.blockWrapper}>
        <Menu />
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
};
