import GetDevicesListSuccessAction from '../../actions/devices/interfaces/getDevicesListSuccessAction.interface';
import DevicesActions from '../../actions/devices/devicesActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import DevicesState from './devicesState.interface';

const initialState: DevicesState = {
  loading: true,
  devices: [],
  adminDevices: [],
  adminTotal: 0,
  offline: 0,
  online: 0,
  total: 0,
};

export default function devicesReducer(
  state = initialState,
  action: ReduxAction<DevicesActions>,
): DevicesState {
  switch (action.type) {
    case DevicesActions.GET_DEVICES_LIST_SUCCESS: {
      const {
        payload: { items },
      } = action as GetDevicesListSuccessAction;

      return {
        ...state,
        devices: items,

      };
    }
    default:
      return state;
  }
}
