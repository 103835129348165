import React, { useEffect, useState } from 'react';
import styles from './systemAccessLog.module.scss';
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  useBlockLayout,
} from 'react-table';
import Search from '../../../assets/icons/search.svg';
import Arrow from '../../../assets/icons/caret.svg';
import Button from '../../../components/Button';
import api from '../../../api';
import UserAccessLog from '../../../interfaces/userAccessLog.interface';
import moment from 'moment';
// @ts-ignore
import { convertArrayToCSV } from 'convert-array-to-csv';
import Clear from '../../../assets/icons/clear.svg';

const SystemAccessLog: React.FC = (): JSX.Element => {
  const [userAccessLog, setUserAccessLog] = useState([] as UserAccessLog[]);
  const getUserAccessLog = async () => {
    const data = await api.users.getUsersAccessList();
    setUserAccessLog(data);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'userName',
        width: 300,
      },
      {
        Header: 'Metamask Account ID',
        accessor: 'metamaskWallet',
        width: 420,
      },
      {
        Header: 'Permission Mode',
        accessor: 'permissionMode',
      },
      {
        Header: 'Last Access',
        accessor: 'time',
        width: 250,
      },
      {
        Header: 'Activity',
        accessor: 'operation',
        width: 100,
      },
    ],
    [],
  );
  useEffect(() => {
    getUserAccessLog();
  }, []);

  const tableInstance = useTable(
    {
      //@ts-ignore
      columns,
      data: userAccessLog,
      initialState: {
        //@ts-ignore
        sortBy: [
          {
            id: 'tokenName',
            desc: false,
          },
        ],
      },
    },

    useFilters,
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  }: any = tableInstance;
  const GlobalFilter = ({ globalFilter, setGlobalFilter }: any) => {
    const [value, setValue] = React.useState(globalFilter);
    const search = useAsyncDebounce(() => {
      setGlobalFilter(value || undefined);
    }, 200);

    const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        search();
      }
    };

    return (
      <div className={styles.searchContainer}>
        <input
          className={styles.searchInput}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={`Search`}
        />
        <div onClick={() => setGlobalFilter('')} className={styles.clearButton}>
          <img className={styles.searchIcon} src={Clear} alt="clear" />
        </div>
        <div onClick={search} className={styles.searchButton}>
          <img className={styles.searchIcon} src={Search} alt="search" />
        </div>
      </div>
    );
  };
  const CustomCell = ({ cell }: any) => {
    if (cell.column.id === 'time') {
      return (
        <td {...cell.getCellProps()} className={styles.wordWrap}>
          <span title={cell.value}>
            {moment.utc(cell.value).format('DD.MM.YYYY, hh:mm:ss')} UTC
          </span>
        </td>
      );
    }
    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
  };
  const csvFromArrayOfObjects = () => {
    const csvFromArrayOfObjects = convertArrayToCSV(userAccessLog, {
      separator: ';',
    });

    let pom = document.createElement('a');
    let blob = new Blob([csvFromArrayOfObjects], {
      type: 'text/csv;charset=utf-8;',
    });
    let url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', 'system_access_log.csv');
    pom.click();
  };
  return (
    <div>
      <div className={styles.pageWrapper}>
        <div className={styles.title}>System Access Log</div>
        <div className={styles.dFlex}>
          <Button type="primary" size="small" onClick={csvFromArrayOfObjects}>
            EXPORT CSV
          </Button>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className={styles.borderSpacingDiv}>
        <table className={styles.borderSpacing} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={styles.tableHeader}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    className={styles.tableColumnWrapper}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Arrow}
                            className={styles.arrowBottom}
                            alt="expanded"
                          />
                        ) : (
                          <img
                            src={Arrow}
                            className={styles.arrowTop}
                            alt="expanded"
                          />
                        )
                      ) : (
                        <span className={styles.emptyArrow} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.tableRows} {...getTableBodyProps()}>
            {rows.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <tr className={styles.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return <CustomCell cell={cell} />;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SystemAccessLog;
