import React, { useState, useEffect } from 'react';
import styles from './header.module.scss';
import Logo from '../../assets/img/brand.svg';
import User from '../../assets/img/user.svg';
import moment from 'moment';
import { useWeb3React } from '@web3-react/core';
import Button from '../Button';
import { formatWallet } from '../../utils/formatString';
import { useSelector } from 'react-redux';
import GlobalState from '../../store/reducers/globalState.interface';

const HeaderBlock = ({
  title,
  description,
  hover,
}: {
  title: string;
  hover?: any;
  description?: any;
}) => {
  return (
    <div className={styles.headerBlock}>
      <div className={styles.title}>{title}</div>
      <div title={hover} className={styles.description}>
        {description}
      </div>
    </div>
  );
};

export default (): JSX.Element => {
  const { deactivate } = useWeb3React();
  const { account } = useWeb3React();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  const disconnectWallet = () => {};
  const firstName = useSelector(
    (state: GlobalState) => state.user.user.firstName,
  );
  const lastName = useSelector(
    (state: GlobalState) => state.user.user.lastName,
  );
  return (
    <div className={styles.header}>
      <img src={Logo} className={styles.logo} alt="logo" />
      <div className={styles.headerBlockWrapper}>
        <HeaderBlock
          title="UTC"
          description={moment(date).utc().format('HH:mm:ss')}
        />
        <HeaderBlock
          title="Local Time"
          description={moment(date).format('HH:mm')}
        />
        <HeaderBlock title="Group" description="Super Admin" />
      </div>
      <div className={styles.userBlock}>
        <img src={User} className={styles.user} alt="logo" />
        <HeaderBlock
          hover={account}
          title="Logged in as"
          // description={formatWallet(account || '')}
          description={`${firstName} ${lastName}`}
        />
        <div className={styles.newBlock} />
        <Button onClick={disconnectWallet} size="medium" type="danger">
          DISCONNECT WALLET
        </Button>
      </div>
    </div>
  );
};
