import CollectionsActions from '../../actions/collections/collectionsActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import CollectionsState from './collectionsState.interface';
import GetCollectionsListSuccessAction from '../../actions/collections/interfaces/getCollectionsListSuccessAction.interface';

const initialState: CollectionsState = {
  loading: true,
  collections: [],
};

export default function CollectionsReducer(
  state = initialState,
  action: ReduxAction<CollectionsActions>,
): CollectionsState {
  switch (action.type) {
    case CollectionsActions.GET_COLLECTIONS_LIST_SUCCESS: {
      const { payload } = action as GetCollectionsListSuccessAction;
      return {
        ...state,
        collections: payload,
      };
    }
    default:
      return state;
  }
}
