import React from 'react';
import styles from './button.module.scss';

export default ({
  children,
  className,
  onClick,
  type,
  size,
}: {
  children: string;
  className?: any;
  onClick?: () => void;
  type: 'primary' | 'danger';
  size: 'small' | 'medium' | 'big';
}): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={`${styles.button} ${styles[size]} ${styles[type]} ${className}`}
    >
      {children}
    </div>
  );
};
