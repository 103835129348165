import React, { useEffect, useState } from 'react';
import styles from './mintingLog.module.scss';
import Arrow from '../../../assets/icons/caret.svg';
import { useDispatch } from 'react-redux';
import { getUsersList } from '../../../store/actions/users';
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  useBlockLayout,
} from 'react-table';
import Search from '../../../assets/icons/search.svg';
import Button from '../../../components/Button';
import moment from 'moment';
// @ts-ignore
import { convertArrayToCSV } from 'convert-array-to-csv';
import Clear from '../../../assets/icons/clear.svg';
import { API_URL } from '../../../constants/api';

const MintingLog: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const [transactions, setTransaction] = useState([] as any);
  const data = React.useMemo(() => transactions, [transactions]);

  const getmintedData = async () => {
    const hash = await fetch(`${API_URL}/transactions`);
    const response = await fetch(
      'https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0xE4FDfd40DF57eCd6cAb7cBdAffa83582dCa965DC&startblock=0&endblock=99999999&page=1&offset=10000&sort=asc&apikey=RV5JCVJG3JDUR3YMZVRWR13SNUPHQFEUAA',
    );
    const operations = await fetch(
      'https://api.etherscan.io/api?module=account&action=txlist&address=0xE4FDfd40DF57eCd6cAb7cBdAffa83582dCa965DC&startblock=0&endblock=99999999999&page=1&offset=10000&sort=asc&apikey=RV5JCVJG3JDUR3YMZVRWR13SNUPHQFEUAA',
    );

    const data = await response.json();
    const hashData = await hash.json();
    const operationsData = await operations.json();
    const operationsObject = operationsData?.result?.reduce(
      (obj: any, item: any) => ((obj[item.hash] = item), obj),
      {},
    );
    console.log('operationsObject', operationsObject);
    const transactions = {} as any;
    data.result.map((nft: any) => {
      const counter = transactions[nft.hash]?.counter
        ? transactions[nft.hash].counter + 1
        : 1;
      transactions[nft.hash] = nft;
      transactions[nft.hash].counter = counter;
      transactions[nft.hash].gasETH =
        transactions[nft.hash].gasUsed *
        transactions[nft.hash].gasPrice *
        0.000000000000000001;
      transactions[nft.hash].gasGWEI =
        transactions[nft.hash].gasPrice * 0.000000001;

      transactions[nft.hash].ip = hashData[nft.hash]?.ip;
      transactions[nft.hash].location = hashData[nft.hash]?.location;
      transactions[nft.hash].price = (
        operationsObject[nft.hash]?.value * 0.000000000000000001
      ).toFixed(3);
    });
    const arrayOfTransactions = Object.values(transactions);
    setTransaction(arrayOfTransactions);
  };
  useEffect(() => {
    getmintedData();
  }, []);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Metamask Account ID',
        accessor: 'to',
        width: 490,
      },
      {
        Header: 'TxHash/TX ID',
        accessor: 'hash',
        width: 690,
      },
      {
        Header: 'Movie',
        accessor: 'movie',
        width: 150,
      },
      {
        Header: 'Location',
        accessor: 'location',
        width: 300,
      },
      {
        Header: 'IP Address',
        accessor: 'ip',
        width: 350,
      },
      {
        Header: 'TimeStamp',
        accessor: 'timeStamp',
        width: 280,
      },
      {
        Header: 'NFT Minted',
        accessor: 'counter',
        width: 120,
      },
      {
        Header: 'NFTs Price (Ether)',
        accessor: 'price',
        width: 150,
      },
      {
        Header: 'Gas price (Gwei)',
        accessor: 'gasGWEI',
        width: 300,
      },
      {
        Header: 'Gas Fee (Ether)',
        accessor: 'gasETH',
        width: 320,
      },
    ],
    [],
  );
  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  const tableInstance = useTable(
    {
      //@ts-ignore
      columns,
      data,
      initialState: {
        //@ts-ignore
        sortBy: [
          {
            id: 'tokenName',
            desc: false,
          },
        ],
      },
    },

    useFilters,
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  }: any = tableInstance;
  const GlobalFilter = ({ globalFilter, setGlobalFilter }: any) => {
    const [value, setValue] = React.useState(globalFilter);
    const search = useAsyncDebounce(() => {
      setGlobalFilter(value || undefined);
    }, 200);

    const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        search();
      }
    };

    return (
      <div className={styles.searchContainer}>
        <input
          className={styles.searchInput}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={`Search`}
        />
        <div onClick={() => setGlobalFilter('')} className={styles.clearButton}>
          <img className={styles.searchIcon} src={Clear} alt="clear" />
        </div>
        <div onClick={search} className={styles.searchButton}>
          <img className={styles.searchIcon} src={Search} alt="search" />
        </div>
      </div>
    );
  };

  const CustomCell = ({ cell }: any) => {
    if (cell.column.Header === 'TimeStamp') {
      return (
        <td {...cell.getCellProps()}>
          {moment.utc(cell.value * 1000).format('DD.MM.YYYY, hh:mm:ss')} UTC
        </td>
      );
    }
    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
  };
  const csvFromArrayOfObjects = () => {
    const formattedTransaction = transactions.map((transaction: any) => {
      return {
        'Metamask Account ID': transaction.to,
        'TxHash/TX ID': transaction.hash,
        Movie: transaction.tokenSymbol,
        TimeStamp: `${moment
          .utc(transaction.timeStamp * 1000)
          .format('DD.MM.YYYY, hh:mm:ss')} UTC`,
        Location: transaction.location,
        'NFT Minted': transaction.counter,
        Price: transaction.price,
        'Gas Price': transaction.gasGWEI,
        'Gas Fee': transaction.gasETH,
      };
    });
    const csvFromArrayOfObjects = convertArrayToCSV(formattedTransaction, {
      separator: ';',
    });
    let pom = document.createElement('a');
    let blob = new Blob([csvFromArrayOfObjects], {
      type: 'text/csv;charset=utf-8;',
    });
    let url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', `transactions.csv`);
    pom.click();
  };

  return (
    <div>
      <div className={styles.pageWrapper}>
        <div className={styles.title}>Minting Log</div>
        <div className={styles.dFlex}>
          <Button type="primary" size="small" onClick={csvFromArrayOfObjects}>
            EXPORT CSV
          </Button>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className={styles.borderSpacingDiv}>
        <table className={styles.borderSpacing} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={styles.tableHeader}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    className={styles.tableColumnWrapper}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Arrow}
                            className={styles.arrowBottom}
                            alt="expanded"
                          />
                        ) : (
                          <img
                            src={Arrow}
                            className={styles.arrowTop}
                            alt="expanded"
                          />
                        )
                      ) : (
                        <span className={styles.emptyArrow} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.tableRows} {...getTableBodyProps()}>
            {rows.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <tr className={styles.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return <CustomCell cell={cell} />;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MintingLog;
