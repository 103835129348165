import GetUserSuccessAction from './interfaces/getUserSuccessAction.interface';
import GetUserAction from './interfaces/getUserAction.interface';
import UserActions from './userActions.enum';
import ReduxAction from '../reduxAction.interface';

export const getUser = (): GetUserAction => ({
  type: UserActions.GET_USER,
});

export const setToken = (token: string): ReduxAction => ({
  type: UserActions.SET_TOKEN,
  payload: { token },
});

export const getUserSuccess = (
  payload: GetUserSuccessAction['payload'],
): GetUserSuccessAction => ({
  type: UserActions.GET_USER_SUCCESS,
  payload,
});
