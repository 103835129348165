import React from 'react';
import styles from './textarea.module.scss';

export default ({
  title,
  onChange,
  value,
  className,
  name,
  errorText,
  errorTouched,
  required,
  onFocus,
}: {
  title: string;
  onChange: any;
  value: string;
  name?: string;
  className?: any;
  errorText?: any;
  errorTouched?: any;
  required?: boolean;
  onFocus?: any;
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
        <span className={styles.bulletPoint}>{required ? '●' : ''}</span>
      </div>
      <textarea
        onChange={onChange}
        className={errorText && errorTouched ? styles.inputError : styles.input}
        name={name || ''}
        onFocus={onFocus}
      >
        {value}
      </textarea>
    </div>
  );
};
