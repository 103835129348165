import axios from 'axios';
import errorsCodesMessages from '../../constants/api/errorsCodesMessages';
import { store } from '../../store';
import { setToken } from '../../store/actions/user';

const ignoredStatusCodes = [401];
const networkErrorMessage = 'Network Error';

/**
 * Intercepts server error responses and shows error messages
 */
export default () => {
  axios.interceptors.response.use(undefined, async (error) => {
    const { response } = error;

    if (401 === error.response.status) {
      store.dispatch(setToken(''));
    }

    if (response) {
      const {
        data: { message, statusCode },
      }: { data: { message: string; statusCode: number } } = response;
      if (
        message &&
        (!statusCode || !ignoredStatusCodes.includes(statusCode))
      ) {
        const errorCodeMessage = (
          errorsCodesMessages as { [key: string]: string }
        )[statusCode];

        console.error(
          `${errorCodeMessage ? `${errorCodeMessage}: ` : ''}${message}`,
        );
      }
    } else if (error.message === networkErrorMessage) {
      console.error(networkErrorMessage);
    }

    return Promise.reject(error);
  });
};
