enum CollectionsActions {
  SET_COLLECTIONS_LOADING = 'SET_COLLECTIONS_LOADING',
  GET_COLLECTIONS_LIST = 'GET_COLLECTIONS_LIST',
  GET_COLLECTIONS_LIST_SUCCESS = 'GET_COLLECTIONS_LIST_SUCCESS',
  GET_ADMIN_COLLECTIONS_LIST = 'GET_ADMIN_COLLECTIONS_LIST',
  GET_ADMIN_COLLECTIONS_LIST_SUCCESS = 'GET_ADMIN_COLLECTIONS_LIST_SUCCESS',
  UPDATE_COLLECTIONS = 'UPDATE_COLLECTIONS',
  UPDATE_MULTIPLE_COLLECTIONS = 'UPDATE_MULTIPLE_COLLECTIONS',
  UPDATE_MULTIPLE_ADMIN_COLLECTIONS = 'UPDATE_MULTIPLE_ADMIN_COLLECTIONS',
  CREATE_COLLECTIONS = 'CREATE_COLLECTIONS',
}

export default CollectionsActions;
