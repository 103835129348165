export const formatCustomString = (text: string) => {
  try {
    const data = JSON.parse(text);
    const newArray = [...data.defaultValues, data.customValue];
    if (newArray.length >= 2) {
      if (data.customValue === '') {
        newArray.pop();
        return newArray.join(', ');
      } else {
        return newArray.join(', ');
      }
    }
    return newArray.join(' ');
  } catch (err) {
    return text;
  }
};
