import React, { useEffect, useState } from 'react';
import styles from './inputArray.module.scss';
import Button from '../Button';
import Autosuggest from 'react-autosuggest';

export default ({
  firstTitle,
  secondTitle,
  onChange,
  value,
  className,
  name,
  autocomplete,
  type,
  onFocus,
}: {
  firstTitle: string;
  secondTitle: string;
  onChange: any;
  value: any[];
  autocomplete: { firstField: string; secondField: string }[];
  name?: string;
  type?: string;
  className?: any;
  onFocus?: any;
}): JSX.Element => {
  const [inputs, setInputs] = useState(
    value?.length
      ? value
      : [
          {
            firstField: '',
            secondField: '',
          },
        ],
  );
  const defaultSuggestions1: string[] = [];
  const defaultSuggestions2: string[] = [];
  autocomplete?.map((item) => {
    if (item.firstField.length) defaultSuggestions1.push(item.firstField);
    if (item.secondField.length) defaultSuggestions2.push(item.secondField);
  });
  const [suggestions1, setSuggestions1] = useState([]);
  const [suggestions2, setSuggestions2] = useState([]);
  useEffect(() => {
    onChange(inputs);
  }, [inputs]);

  const getSuggestions1 = (value: string) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? defaultSuggestions1
      : defaultSuggestions1
          .sort()
          .filter(
            (option: string) =>
              option.toLowerCase().slice(0, inputLength) === inputValue,
          );
  };

  const getSuggestionValue2 = (suggestion: string) => suggestion;

  const renderSuggestion2 = (suggestion: string) => <div>{suggestion}</div>;

  const onSuggestionsFetchRequested2 = ({ value }: { value: string }) => {
    // @ts-ignore
    setSuggestions2(getSuggestions2(value));
  };

  const onSuggestionsClearRequested2 = () => {
    setSuggestions2([]);
  };

  const getSuggestions2 = (value: string) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? defaultSuggestions2
      : defaultSuggestions2
          .sort()
          .filter(
            (option: string) =>
              option.toLowerCase().slice(0, inputLength) === inputValue,
          );
  };

  const getSuggestionValue1 = (suggestion: string) => suggestion;

  const renderSuggestion1 = (suggestion: string) => <div>{suggestion}</div>;

  const onSuggestionsFetchRequested1 = ({ value }: { value: string }) => {
    // @ts-ignore
    setSuggestions1(getSuggestions1(value));
  };

  const onSuggestionsClearRequested1 = () => {
    setSuggestions1([]);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{firstTitle}</div>
        <div className={styles.secondTitle}>{secondTitle}</div>
      </div>

      {inputs.map((inp, i) => {
        return (
          <div className={styles.inputWrapper}>
            <div className={styles.index}>#{i + 1}</div>
            <Autosuggest
              suggestions={suggestions1}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested1}
              onSuggestionsClearRequested={onSuggestionsClearRequested1}
              getSuggestionValue={getSuggestionValue1}
              renderSuggestion={renderSuggestion1}
              inputProps={{
                value: inputs[i].firstField,
                onChange: (e, { newValue }) => {
                  setInputs((oldArray) => {
                    if (onFocus) {
                      onFocus();
                    }
                    oldArray[i].firstField = newValue;
                    return [...oldArray];
                  });
                },
              }}
            />

            <Autosuggest
              suggestions={suggestions2}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested2}
              onSuggestionsClearRequested={onSuggestionsClearRequested2}
              getSuggestionValue={getSuggestionValue2}
              renderSuggestion={renderSuggestion2}
              theme={styles}
              inputProps={{
                value: inputs[i].secondField,
                onChange: (e, { newValue }) => {
                  setInputs((oldArray) => {
                    if (onFocus) {
                      onFocus();
                    }
                    oldArray[i].secondField = newValue;
                    return [...oldArray];
                  });
                },
              }}
            />

            <div className={styles.buttonWrapper}>
              <Button
                type="primary"
                size="medium"
                onClick={() => {
                  if (i > 0) {
                    setInputs(inputs.filter((item, index) => index !== i));
                  }
                }}
              >
                -
              </Button>
            </div>
            {i + 1 === inputs.length && (
              <div className={styles.buttonWrapper}>
                <Button
                  type="primary"
                  size="medium"
                  onClick={() => {
                    setInputs((oldArray) => [
                      ...oldArray,
                      {
                        firstField: '',
                        secondField: '',
                      },
                    ]);
                  }}
                >
                  +
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
