import React, { useRef, useState } from 'react';
import styles from './fileInput.module.scss';
import Button from '../Button';

export default ({
  title,
  setFile,
  onChange,
  value,
  className,
  name,
  accept,
  errorText,
  errorTouched,
  required,
}: {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name?: string;
  accept?: string;
  className?: any;
  setFile?: any;
  errorText?: any;
  errorTouched?: any;
  required?: boolean;
}): JSX.Element => {
  const inputFile = useRef(null);
  const [fileName, setFileName] = useState('');
  return (
    <div className={styles.wrapper}>
      <input
        type="file"
        id={title}
        ref={inputFile}
        style={{ display: 'none' }}
        accept={accept ? accept : '.png,.jpg,.jpeg'}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();
          // @ts-ignore
          const file = event.target.files[0];
          setFileName(file?.name);
          setFile(file);
        }}
      />
      <div className={styles.title}>
        {title}
        <span className={styles.bulletPoint}>{required ? '●' : ''}</span>
      </div>
      <div className={styles.uploadWrapper}>
        <input
          className={
            errorText && errorTouched ? styles.inputError : styles.input
          }
          name={name || ''}
          value={fileName}
          onChange={onChange}
          placeholder="Select ﬁle"
        />
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            size="medium"
            onClick={() => {
              // @ts-ignore
              inputFile.current.click();
            }}
          >
            MODIFY / UPLOAD
          </Button>
        </div>
      </div>
    </div>
  );
};
