import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import styles from './login.module.scss';
import { injected } from '../../utils/connectors';
import Logo from '../../assets/img/brand.svg';
import api from '../../api';
import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSuccess, setToken } from '../../store/actions/user';
import GlobalState from '../../store/reducers/globalState.interface';

export default function Login({ setLoggedIn }: { setLoggedIn: any }) {
  const { active, activate } = useWeb3React();
  const [error, setError] = useState(false);
  const token = useSelector((state: GlobalState) => state.user.token);
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoggedIn(active && !!token);
  }, [active, token]);

  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {
      setError(true);
    }
  }
  const signMessage = async (message: string) => {
    try {
      // @ts-ignore
      const ethereum = window.ethereum;

      if (!ethereum)
        throw new Error('No crypto wallet found. Please install it.');

      await ethereum.send('eth_requestAccounts');
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);
      const address = await signer.getAddress();
      return {
        message,
        signature,
        address,
      };
    } catch (err: any) {
      setError(true);
    }
  };

  const loginUser = async (acc: string) => {
    try {
      const nonce = await api.users.getNonce(acc);
      const signData = await signMessage(nonce);
      if (signData) {
        const authToken: any = await api.users.getAuthToken(
          nonce,
          signData.signature,
        );
        dispatch(setToken(authToken.access_token));
        dispatch(getUserSuccess(authToken.user));
        setLoggedIn(true);
      } else throw new Error('error');
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (account && !token) {
      loginUser(account);
    }
  }, [account, token]);

  return (
    <div className={styles.blockWrapper}>
      <div>
        <div className={styles.logoWrapper}>
          <img src={Logo} className={styles.logo} alt="logo" />
        </div>
        <div className={styles.buttonWrapper}>
          <div></div>
          <div className={styles.connectButton} onClick={connect}>
            CONNECT WALLET
          </div>
          {error ? (
            <div className={styles.error}>
              Unknown user, please get in contact to your administrator.
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}
