import React, { useEffect, useState } from 'react';
import styles from './ckeckbox.module.scss';
import ActiveCheckbox from '../../assets/img/active-round-check.svg';
import InactiveCheckbox from '../../assets/img/inactive-round-check.svg';

export default ({
  title,
  setActive,
  options,
  active,
  onFocus,
}: {
  custom?: boolean;
  title?: string;
  active: string;
  setActive: any;
  options: any;
  name?: string;
  className?: any;
  onFocus?: any;
}): JSX.Element => {
  let dataFromServer = {
    customValue: '',
    defaultValues: [] as any[],
  };
  try {
    dataFromServer = JSON.parse(active);
  } catch (err) {
    dataFromServer.defaultValues = [active];
  }

  const [cust, setCust] = useState(dataFromServer.customValue);
  const [values, setValues] = useState(dataFromServer.defaultValues);

  const data = {
    customValue: cust,
    defaultValues: values,
  };

  useEffect(() => {
    console.log('update');
    setActive(JSON.stringify(data));
  }, [values, cust]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.optionsWrapper}>
        {options.map((val: any) => {
          return (
            <>
              <div
                onClick={() => {
                  if (onFocus) {
                    onFocus();
                  }
                  if (values.indexOf(val.value) >= 0) {
                    values.splice(values.indexOf(val.value), 1);
                    setValues([...values]);
                  } else {
                    setValues([...values, val.value]);
                  }
                }}
                className={styles.checkboxWrapper}
              >
                <img
                  src={
                    values.indexOf(val.value) >= 0
                      ? ActiveCheckbox
                      : InactiveCheckbox
                  }
                  alt="inactive"
                />
                <div className={styles.value}>{val.label}</div>
              </div>
            </>
          );
        })}
        <>
          <div className={styles.wrapper}>
            <div className={styles.title}>
              <div>{title} Custom</div>
              <input
                type={'text'}
                className={styles.input}
                value={cust}
                onChange={(event) => setCust(event.target.value)}
              ></input>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
