import React, { useState } from 'react';
import styles from './ckeckbox.module.scss';
import ActiveCheckbox from '../../assets/img/active-round-check.svg';
import InactiveCheckbox from '../../assets/img/inactive-round-check.svg';
import Button from '../Button';

export default ({
  title,
  setActive,
  options,
  active,
  onFocus,
}: {
  title?: string;
  active: string;
  setActive: any;
  options: any;
  name?: string;
  className?: any;
  onFocus?: any;
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.optionsWrapper}>
        {options.map((val: any) => {
          return (
            <>
              <div
                onClick={() => {
                  if (onFocus) {
                    onFocus();
                  }
                  setActive(val.value);
                }}
                className={styles.checkboxWrapper}
              >
                <img
                  src={active === val.value ? ActiveCheckbox : InactiveCheckbox}
                  alt="inactive"
                />
                <div className={styles.value}>{val.label}</div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
