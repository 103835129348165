import GetUsersListSuccessAction from './interfaces/getUsersListSuccessAction.interface';
import GetUsersListAction from './interfaces/getUsersListAction.interface';
import UsersActions from './usersActions.enum';

export const getUsersList = (): GetUsersListAction => ({
  type: UsersActions.GET_USERS_LIST,
});

export const getUsersListSuccess = (
  payload: GetUsersListSuccessAction['payload'],
): GetUsersListSuccessAction => ({
  type: UsersActions.GET_USERS_LIST_SUCCESS,
  payload,
});
