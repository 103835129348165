import React, { useEffect, useRef, useState } from 'react';
import styles from './editNFT.module.scss';
import Input from '../../../components/Input';
import { Formik } from 'formik';
import Select from '../../../components/Select';
import Check from '../../../components/Check';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import ArrowLeft from '../../../assets/img/arrow-left.svg';
import ArrowRight from '../../../assets/img/arrow-right.svg';
import defaultImage from '../../../assets/img/defaultImage.png';
import RoundCheckbox from '../../../components/RoundCheckbox';
// import CustomArray from '../../../components/CustomArray';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api';
import { API_URL } from '../../../constants/api';
import routes from '../../../routing/config';
import InputArray from '../../../components/InputArray';
import * as Yup from 'yup';
import Toastify from 'toastify-js';
import CustomArray from '../../../components/CustomArray';
import ModalDialog from '../../../components/ModalDialog';
import RoundCheckboxCustom from '../../../components/RoundCheckboxCustom';

const statusMap = {
  progress: 'In Progress',
  final: 'Final',
  preview: 'Preview',
  attention: 'Attention',
};

const statusOptions = [
  {
    value: 'progress',
    label: 'In Progress',
  },
  {
    value: 'final',
    label: 'Final',
  },
  {
    value: 'preview',
    label: 'Preview',
  },
  {
    value: 'attention',
    label: 'Attention',
  },
];

const timeOptions = [
  {
    value: 'none',
    label: 'NONE',
  },
  {
    value: 'day',
    label: 'DAY',
  },
  {
    value: 'night',
    label: 'NIGHT',
  },
];

const placeOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'int',
    label: 'INT.',
  },
  {
    value: 'ext',
    label: 'EXT.',
  },
  {
    value: 'intExt',
    label: 'INT./EXT.',
  },
  {
    value: 'intAmpExt',
    label: 'INT. & EXT.',
  },
];

const transitionOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'fadeIn',
    label: 'Fade In',
  },
  {
    value: 'fadeOut',
    label: 'Fade Out',
  },
  {
    value: 'crossDissolve',
    label: 'Cross Dissolve',
  },
  {
    value: 'wipe',
    label: 'Wipe',
  },
];

const shotSizeOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'ws',
    label: 'WS',
  },
  {
    value: 'mws',
    label: 'MWS',
  },
  {
    value: 'ms',
    label: 'MS',
  },
  {
    value: 'mcu',
    label: 'MCU',
  },

  {
    value: 'cu',
    label: 'CU',
  },

  {
    value: 'ecu',
    label: 'ECU',
  },

  {
    value: 'det',
    label: 'DET',
  },

  {
    value: 'ots',
    label: 'OTS',
  },
  {
    value: 'ews',
    label: 'EWS',
  },
];

const EditNFT: React.FC = (): JSX.Element => {
  const history = useNavigate();
  const [protectedNFT, setProtectedNFT] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [nftData, setNFTData] = useState<any>({});
  const [collectionData, setCollectionData] = useState<any>({});
  const formRef = useRef();
  const [num, setNum] = useState('');
  let { collection, id } = useParams<{ collection: string; id: string }>();

  const nextNFT = () => {
    if (+id! < +nftData.total) {
      history(`${routes.editNFT.route}/${collection}/${+id! + 1}`);
      setWasChanged(false);
    }
  };

  const previousNFT = () => {
    if (+id! < +nftData.total && +id! !== 0) {
      history(`${routes.editNFT.route}/${collection}/${+id! - 1}`);
      setWasChanged(false);
    }
  };

  const jump = () => {
    if (num !== '' && +num > 0 && num < nftData.total) {
      history(`${routes.editNFT.route}/${collection}/${num}`);
      setWasChanged(false);
    }
  };
  const AddNFTSchema = Yup.object().shape({
    tokenName: Yup.string().required().trim(),
    nftName: Yup.string().required().trim(),
    // remark: Yup.string().required(),
    // special: Yup.string().required(),
    //  weather: Yup.array().of(Yup.string().trim().required()).required(),
    // location: Yup.array().of(Yup.string().trim().required()).required(),
    // action: Yup.array().of(Yup.string().trim().required()).required(),
    // costumes: Yup.array().of(Yup.string().trim().required()).required(),
    // makeup: Yup.array().of(Yup.string().trim().required()).required(),
    // animals: Yup.array().of(Yup.string().trim().required()).required(),
    // creatures: Yup.array().of(Yup.string().trim().required()).required(),
    // dressing: Yup.array().of(Yup.string().trim().required()).required(),
    // propsItems: Yup.array().of(Yup.string().trim().required()).required(),
    // actingProps: Yup.array().of(Yup.string().trim().required()).required(),
    // actingVehicle: Yup.array().of(Yup.string().trim().required()).required(),
    // actingWeapons: Yup.array().of(Yup.string().trim().required()).required(),
    // signs: Yup.array().of(Yup.string().trim().required()).required(),
  });
  useEffect(() => {
    if (id && collection) {
      setNFTData({});
      setProtectedNFT(false);
      api.nfts.getNFT(collection, id).then((data) => {
        setNFTData(data);
        setProtectedNFT(data.protect);
      });
    }
  }, [id]);

  useEffect(() => {
    if (collection && id) {
      setNFTData({});
      api.collections.getCollectionByName(collection).then((data) => {
        setCollectionData(data);
      });
    }
  }, [collection, id]);
  return (
    <div>
      <div className={styles.fixedHeader}>
        <div className={styles.center}>
          <div className={styles.arrowsWrapper}>
            {!wasChanged ? (
              <img
                src={ArrowLeft}
                onClick={previousNFT}
                className={styles.arrow}
                alt="logo"
              />
            ) : (
              <ModalDialog
                text="Are you sure - you have unsaved changes"
                onSubmit={previousNFT}
              >
                <img src={ArrowLeft} className={styles.arrow} alt="logo" />
              </ModalDialog>
            )}
            <div className={styles.counter}>
              {id} of {nftData.total}
            </div>
            {!wasChanged ? (
              <img
                onClick={nextNFT}
                src={ArrowRight}
                className={styles.arrow}
                alt="logo"
              />
            ) : (
              <ModalDialog
                text="Are you sure - you have unsaved changes"
                onSubmit={nextNFT}
              >
                <img src={ArrowRight} className={styles.arrow} alt="logo" />
              </ModalDialog>
            )}
          </div>
          {/*<div className={styles.headerInfoWrapper}>*/}
          {/*  <div className={styles.subTitle}>Token Name</div>*/}
          {/*  <div className={styles.disabledInfo}>{nftData.tokenName}</div>*/}
          {/*</div>*/}
          <div className={styles.headerInfoWrapper}>
            <div className={styles.subTitle}>
              Old NFT Id:{' '}
              <span className={styles.time}>{nftData.movieshotId}</span>
            </div>
          </div>
          {/*<div className={styles.headerInfoWrapper}>*/}
          {/*  <div className={styles.subTitle}>Editing Status</div>*/}
          {/*  <div className={styles.disabledInfo}>*/}
          {/*    {statusMap[nftData.editingStatus]}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.headerInfoWrapper}>
            <div className={styles.subTitle}>
              Screen Time:{' '}
              <span className={styles.time}>{nftData.screenTime} Seconds</span>
            </div>
          </div>

          <div className={styles.headerInfoWrapper}>
            <div className={styles.subTitle}>
              Time Code:{' '}
              <span className={styles.time}>
                {nftData.timecode?.split('-').join(':')}
              </span>
            </div>
          </div>
          <div className={styles.headerInfo}>
            <div className={styles.subTitle}>
              NFT :
              <input
                type="number"
                onChange={(event) => setNum(event.target.value)}
              />
            </div>
            <div>
              {!wasChanged ? (
                <Button size="medium" type="primary" onClick={jump}>
                  JUMP
                </Button>
              ) : (
                <ModalDialog
                  text="Are you sure - you have unsaved changes"
                  onSubmit={jump}
                >
                  <Button size="medium" type="primary">
                    JUMP
                  </Button>
                </ModalDialog>
              )}
            </div>
          </div>
        </div>

        <div>
          <img
            className={styles.nftImage}
            src={!nftData.image ? defaultImage : nftData.image}
            alt="nft image"
          />
        </div>
        <div>
          <div className={styles.buttonsWrapper}>
            <Check
              value="Exclude from minting"
              onFocus={() => {
                setWasChanged(true);
              }}
              active={protectedNFT}
              onChange={setProtectedNFT}
            />
            <div className={styles.buttonWrapper}>
              <Button size="medium" onClick={() => {}} type="danger">
                ABORT
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                size="medium"
                onClick={() => {
                  if (formRef.current) {
                    // @ts-ignore
                    formRef.current.handleSubmit();
                  }
                }}
                type="primary"
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </div>
      {nftData.id && collectionData.id && (
        <div className={styles.pageWrapper}>
          <div className={styles.title}>MovieShots (NFTs)</div>
          <Formik
            /*//@ts-ignore*/
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              collectionName: collection,
              protect: protectedNFT,
              tokenName: nftData.tokenName,
              nftName: nftData.nftName,
              editingStatus: nftData.editingStatus,
              remark: nftData.remark,
              shotSize: nftData.shotSize,
              transition: nftData.transition || 'none',
              specials: nftData.specials || [],
              placeOfScene: nftData.placeOfScene || 'none',
              timeOfDay: nftData.timeOfDay || 'none',
              quotes: nftData.quotes,
              castMembers: nftData.castMembers,
              location: nftData.location,
              weather: nftData.weather,
              action: nftData.action,
              costumes: nftData.costumes,
              makeup: nftData.makeup,
              animals: nftData.animals,
              creatures: nftData.creatures,
              dressing: nftData.dressing,
              propsItems: nftData.propsItems,
              actingProps: nftData.actingProps,
              actingVehicle: nftData.actingVehicle,
              actingWeapons: nftData.actingWeapons,
              signs: nftData.signs,
            }}
            validationSchema={AddNFTSchema}
            onSubmit={(values, { setSubmitting }) => {
              api.nfts
                .updateNFT(nftData.id, values)
                .then(() => {
                  setWasChanged(false);
                  Toastify({
                    text: 'All data has been sucessfully saved.',
                    duration: 3000,
                    newWindow: true,
                    close: true,
                    gravity: 'top', // 'top' or 'bottom'
                    position: 'right', // 'left', 'center' or 'right'
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                      background: '#A9C335',
                    },
                  }).showToast();
                })
                .catch((err) => {
                  Toastify({
                    // text: 'Please make sure that all indicated fields are being filled out.',
                    text: err,
                    duration: 3000,
                    newWindow: true,
                    close: true,
                    gravity: 'top', // 'top' or 'bottom'
                    position: 'right', // 'left', 'center' or 'right'
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                      background: '#C52F2F',
                    },
                  }).showToast();
                });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [wasSubmitting, setWasSubmitting] = useState(isSubmitting);
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (
                  !isSubmitting &&
                  wasSubmitting &&
                  JSON.stringify(errors) !== JSON.stringify({})
                ) {
                  Toastify({
                    text: 'Please make sure that all indicated fields are being filled out.',
                    duration: 3000,
                    newWindow: true,
                    close: true,
                    gravity: 'top', // 'top' or 'bottom'
                    position: 'right', // 'left', 'center' or 'right'
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                      background: '#C52F2F',
                    },
                  }).showToast();
                }
              }, [isSubmitting, wasSubmitting, errors, values]);
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                setWasSubmitting(isSubmitting);
              }, [isSubmitting]);

              return (
                <>
                  <div>
                    <div className={styles.divider} />
                    <div className={styles.subTitle}>Name</div>
                  </div>

                  <div className={styles.blockWrapper}>
                    <Input
                      className={styles.inputWrapper}
                      value={values.tokenName}
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      errorText={errors.tokenName}
                      errorTouched={touched.tokenName}
                      name="tokenName"
                      onChange={handleChange}
                      title="Token Name"
                      required
                    />
                    <Input
                      className={styles.inputWrapper}
                      value={values.nftName}
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      errorText={errors.nftName}
                      errorTouched={touched.nftName}
                      name="nftName"
                      onChange={handleChange}
                      title="NFT Name"
                      required
                    />
                  </div>
                  <div>
                    <div className={styles.divider} />
                    <div className={styles.subTitle}>Editing Status</div>
                  </div>
                  <div className={styles.blockWrapper}>
                    <Select
                      title="Status"
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      onChange={(e: any) => {
                        setFieldValue('editingStatus', e.value);
                      }}
                      options={statusOptions}
                      value={values.editingStatus}
                    />
                    <Textarea
                      value={values.remark}
                      name="remark"
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      // errorText={errors.remark}
                      // errorTouched={touched.remark}
                      // required
                      onChange={handleChange}
                      title="Remark"
                    />
                  </div>

                  <div>
                    <div className={styles.divider} />
                    <div className={styles.subTitle}>Technical Properties</div>
                  </div>
                  <div className={styles.blockWrapper}>
                    <RoundCheckboxCustom
                      title="Shot Size"
                      options={shotSizeOptions}
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      active={values.shotSize}
                      setActive={(e: any) => {
                        setFieldValue('shotSize', e);
                      }}
                    />
                    <RoundCheckbox
                      title="Transition"
                      options={transitionOptions}
                      active={values.transition}
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      setActive={(e: any) => {
                        setFieldValue('transition', e);
                      }}
                    />
                    <InputArray
                      autocomplete={collectionData.specials}
                      title="Special"
                      onFocus={() => {
                        setWasChanged(true);
                      }}
                      // errorText={errors.dressing}
                      // errorTouched={touched.dressing}
                      // required
                      value={values.specials}
                      onChange={(inputs: any) => {
                        setFieldValue('specials', inputs); //added in '' value from backend
                      }}
                    />
                  </div>
                  <div>
                    <div className={styles.divider} />
                    <div className={styles.subTitle}>MovieShot Metatags</div>
                  </div>
                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.grey}>Scene Properties</div>
                    </div>
                    <div>
                      <RoundCheckbox
                        title="Place of scene"
                        options={placeOptions}
                        active={values.placeOfScene}
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        setActive={(e: any) => {
                          setFieldValue('placeOfScene', e);
                        }}
                      />
                      <RoundCheckboxCustom
                        title="Time Of Day"
                        options={timeOptions}
                        active={values.timeOfDay}
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        setActive={(e: any) => {
                          setFieldValue('timeOfDay', e);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.greyForArray}>Cast Members</div>
                    </div>
                    <div>
                      <CustomArray
                        autocomplete={collectionData.castMember}
                        firstTitle="Actor"
                        secondTitle="Character"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        value={values.castMembers}
                        onChange={(inputs: any) => {
                          setFieldValue('castMembers', inputs);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.greyForArray}>Set Properties</div>
                    </div>
                    <div>
                      <InputArray
                        autocomplete={collectionData.location}
                        title="Location"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.location}
                        // errorTouched={touched.location}
                        // required
                        value={values.location}
                        onChange={(inputs: any) => {
                          setFieldValue('location', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.weather}
                        title="Weather"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.weather}
                        // errorTouched={touched.weather}

                        value={values.weather}
                        onChange={(inputs: any) => {
                          setFieldValue('weather', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.action}
                        title="Action"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.action}
                        // errorTouched={touched.action}
                        // required
                        value={values.action}
                        onChange={(inputs: any) => {
                          setFieldValue('action', inputs);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.greyForArray}>Look</div>
                    </div>
                    <div>
                      <InputArray
                        autocomplete={collectionData.costumes}
                        title="Costumes"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.costumes}
                        // errorTouched={touched.costumes}
                        // required
                        value={values.costumes}
                        onChange={(inputs: any) => {
                          setFieldValue('costumes', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.makeUp}
                        title="Make-Up & Hair"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.makeUp}
                        // errorTouched={touched.makeUp}
                        // required
                        value={values.makeup}
                        onChange={(inputs: any) => {
                          setFieldValue('makeup', inputs);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.greyForArray}>Alive</div>
                    </div>
                    <div>
                      <InputArray
                        autocomplete={collectionData.animals}
                        title="Animals"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.animals}
                        // errorTouched={touched.animals}
                        // required
                        value={values.animals}
                        onChange={(inputs: any) => {
                          setFieldValue('animals', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.creatures}
                        title="Creatures"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.creatures}
                        // errorTouched={touched.creatures}
                        // required
                        value={values.creatures}
                        onChange={(inputs: any) => {
                          setFieldValue('creatures', inputs);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.greyForArray}>Props</div>
                    </div>
                    <div>
                      <InputArray
                        autocomplete={collectionData.dressing}
                        title="Set"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.dressing}
                        // errorTouched={touched.dressing}
                        // required
                        value={values.dressing}
                        onChange={(inputs: any) => {
                          setFieldValue('dressing', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.propsItems}
                        title="Props Items"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.propsItems}
                        // errorTouched={touched.propsItems}
                        // required
                        value={values.propsItems}
                        onChange={(inputs: any) => {
                          setFieldValue('propsItems', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.actingProps}
                        title="Acting Props"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.actingProps}
                        // errorTouched={touched.actingProps}
                        // required
                        value={values.actingProps}
                        onChange={(inputs: any) => {
                          setFieldValue('actingProps', inputs);
                        }}
                      />
                      <InputArray
                        autocomplete={collectionData.actingVehicle}
                        title="Acting Vehicle"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.actingVehicle}
                        // errorTouched={touched.actingVehicle}
                        // required
                        value={values.actingVehicle}
                        onChange={(inputs: any) => {
                          setFieldValue('actingVehicle', inputs);
                        }}
                      />
                      {/*<InputArray*/}
                      {/*  autocomplete={collectionData.actingWeapons}*/}
                      {/*  title="Acting Weapons"*/}
                      {/*  // errorText={errors.actingWeapons}*/}
                      {/*  // errorTouched={touched.actingWeapons}*/}
                      {/*  // required*/}
                      {/*  value={values.actingWeapons}*/}
                      {/*  onChange={(inputs: any) => {*/}
                      {/*    setFieldValue('actingWeapons', inputs);*/}
                      {/*  }}*/}
                      {/*/>*/}
                      <InputArray
                        autocomplete={collectionData.signs}
                        title="Signages"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        // errorText={errors.signs}
                        // errorTouched={touched.signs}
                        // required
                        value={values.signs}
                        onChange={(inputs: any) => {
                          setFieldValue('signs', inputs);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.generalGreyColumn}>
                    <div>
                      <div className={styles.greyForArrayQuotes}>Quotes</div>
                    </div>
                    <div>
                      <Textarea
                        value={values.quotes}
                        name="quotes"
                        onFocus={() => {
                          setWasChanged(true);
                        }}
                        onChange={handleChange}
                        title="Scene Quotes"
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.divider} />
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};
export default EditNFT;
