export const normalizeUsers = (data: any) => {
  let normalizedUsers: any[] = [];
  data.map((info: any) => {
    const newUser = {
      fullName: info.firstName + ' ' + info.lastName,
      delete: info.id,
      ...info,
    };

    normalizedUsers.push(newUser);
  });
  return normalizedUsers;
};
