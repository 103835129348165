import axios from 'axios';
import { API_URL } from '../../../constants/api';
import NFTRoutes from './enums/collectionsRoutes.enum';
import Collections from '../../../interfaces/collections.interface';
import CollectionsRoutes from '../collections/enums/collectionsRoutes.enum';

export default {
  getNFT: async (token: string, id: string): Promise<any> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${NFTRoutes.NFTs}/byTokenAndNftId`,
      params: {
        token,
        id,
      },
    });

    return data;
  },
  updateNFT: async (id: string, nftData: any): Promise<any> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${NFTRoutes.NFTs}/${id}`,
      data: nftData,
    });
    return data;
  },
  deleteNFT: async (id: number): Promise<any> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${NFTRoutes.NFTs}/${id}`,
    });
    return data;
  },
};
