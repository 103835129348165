import GetDevicesListSuccessAction from '../../actions/devices/interfaces/getDevicesListSuccessAction.interface';
import ReduxAction from '../../actions/reduxAction.interface';
import UsersActions from '../../actions/users/usersActions.enum';
import GetUsersListSuccessAction from '../../actions/users/interfaces/getUsersListSuccessAction.interface';
import UsersState from './usersState.interface';
const initialState: UsersState = {
  loading: false,
  users: [],
};

export default function UsersReducer(
  state = initialState,
  action: ReduxAction<UsersActions>,
): UsersState {
  switch (action.type) {
    case UsersActions.GET_USERS_LIST_SUCCESS: {
      const { payload } = action as GetUsersListSuccessAction;

      return {
        ...state,
        users: payload,
      };
    }
    default:
      return state;
  }
}
