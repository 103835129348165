import { call, put, takeEvery } from 'redux-saga/effects';
import CollectionsActions from '../actions/collections/collectionsActions.enum';
import GetCollectionsListAction from '../actions/collections/interfaces/getCollectionsListAction.interface';
import api from '../../api';
import { getCollectionsListSuccess } from '../actions/collections';
import Collections from '../../interfaces/collections.interface';

function* getCollectionsRequestWorker() {
  try {
    const responseData: Collections[] = yield call(
      api.collections.getCollectionsList,
    );

    yield put(getCollectionsListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }
}

export default function* collectionsWatcher() {
  yield takeEvery<GetCollectionsListAction>(
    CollectionsActions.GET_COLLECTIONS_LIST,
    getCollectionsRequestWorker,
  );
}
