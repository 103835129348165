import React, { Component, useEffect, useState } from 'react';
import styles from './addUser.module.scss';
import { Formik } from 'formik';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import Check from '../../../components/Check';
import api from '../../../api';
import User from '../../../interfaces/user.interface';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routing/config';
import * as Yup from 'yup';
import Toastify from 'toastify-js';

const userStatusOptions = [
  {
    value: 'user',
    label: 'User',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'superadmin',
    label: 'Super Admin',
  },
];
const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
const AddUserSchema = Yup.object().shape({
  userName: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  permissionMode: Yup.string().required(),
  metamaskAccount1: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  firstName: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(),
  lastName: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(),
  jobTitle: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(),
  mobilePhone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required(),
  email: Yup.string().email('Invalid email').required(),
});

const AddUser = ({ history }: { history?: any }): JSX.Element => {
  const navigation = useNavigate();
  const user = history?.location?.state?.user;
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>User Management</div>
      <Formik
        initialValues={{
          active: user?.active || true,
          userName: user?.userName || '',
          permissionMode: user?.permissionMode || '',
          metamaskAccount1: user?.metamaskWallet1 || '',
          metamaskAccount2: user?.metamaskWallet2 || '',
          firstName: user?.firstName || '',
          middleName: user?.middleName || '',
          lastName: user?.lastName || '',
          jobTitle: user?.jobTitle || '',
          function: user?.function || '',
          businessPhone: user?.businessPhone || '',
          mobilePhone: user?.mobilePhone || '',
          email: user?.email || '',
          remarks: user?.remarks || '',
        }}
        validationSchema={AddUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          let createUser: any = {};
          createUser.userName = values.userName;
          createUser.active = values.active;
          createUser.email = values.email;
          createUser.permissionMode = values.permissionMode;
          createUser.firstName = values.firstName;
          createUser.lastName = values.lastName;
          createUser.middleName = values.middleName;
          createUser.jobTitle = values.jobTitle;
          createUser.function = values.function;
          createUser.businessPhone = values.businessPhone;
          createUser.mobilePhone = values.mobilePhone;
          createUser.metamaskWallet1 = values.metamaskAccount1;
          createUser.metamaskWallet2 = values.metamaskAccount2;

          if (user) {
            api.users
              .updateUser(user.id, createUser)
              .then(() => {
                Toastify({
                  text: 'All data has been sucessfully saved.',
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#A9C335',
                  },
                }).showToast();
                navigation(routes.usersManagement.route);
              })
              .catch((err: string) => {
                Toastify({
                  // text: 'Please make sure that all indicated fields are being filled out.',
                  text: err,
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#C52F2F',
                  },
                }).showToast();
              });
          } else {
            api.users
              .createUser(createUser as User)
              .then(() => {
                navigation(routes.usersManagement.route);
              })
              .catch((err: string) => {
                Toastify({
                  // text: 'Please make sure that all indicated fields are being filled out.',
                  text: err,
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top', // `top` or `bottom`
                  position: 'right', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: '#C52F2F',
                  },
                }).showToast();
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [wasSubmitting, setWasSubmitting] = useState(isSubmitting);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (
              !isSubmitting &&
              wasSubmitting &&
              JSON.stringify(errors) !== JSON.stringify({})
            ) {
              Toastify({
                text: 'Please lease make sure that all indicated fields are being filled out',
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top', // `top` or `bottom`
                position: 'right', // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                  background: '#C52F2F',
                },
              }).showToast();
            }
          }, [isSubmitting, wasSubmitting, errors, values]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setWasSubmitting(isSubmitting);
          }, [isSubmitting]);
          return (
            <>
              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>General</div>
              </div>

              <div className={styles.blockWrapper}>
                <Check
                  value="Active"
                  active={values.active}
                  onChange={() => {
                    setFieldValue('active', !values.active);
                  }}
                />
                <Input
                  className={styles.inputWrapper}
                  errorText={errors.userName}
                  errorTouched={touched.userName}
                  value={values.userName}
                  name="userName"
                  onChange={handleChange}
                  title="User Name"
                  required
                />
                <Select
                  title="Permission Mode"
                  onChange={(e: any) => {
                    setFieldValue('permissionMode', e.value);
                  }}
                  errorText={errors.permissionMode}
                  errorTouched={touched.permissionMode}
                  value={values.permissionMode}
                  options={userStatusOptions}
                  required
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.metamaskAccount1}
                  errorText={errors.metamaskAccount1}
                  errorTouched={touched.metamaskAccount1}
                  name="metamaskAccount1"
                  onChange={handleChange}
                  title="Metamask Account 1"
                  required
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.metamaskAccount2}
                  name="metamaskAccount2"
                  onChange={handleChange}
                  title="Metamask Account 2"
                />
              </div>

              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Personal Data</div>
              </div>

              <div className={styles.blockWrapper}>
                <Input
                  className={styles.inputWrapper}
                  value={values.firstName}
                  errorText={errors.firstName}
                  errorTouched={touched.firstName}
                  name="firstName"
                  onChange={handleChange}
                  title="First Name"
                  required
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.middleName}
                  name="middleName"
                  onChange={handleChange}
                  title="Middle Name"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.lastName}
                  errorText={errors.lastName}
                  errorTouched={touched.lastName}
                  name="lastName"
                  onChange={handleChange}
                  title="Last Name"
                  required
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.jobTitle}
                  errorText={errors.jobTitle}
                  errorTouched={touched.jobTitle}
                  name="jobTitle"
                  onChange={handleChange}
                  title="Job Title"
                  required
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.function}
                  name="function"
                  onChange={handleChange}
                  title="Function"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.businessPhone}
                  name="businessPhone"
                  onChange={handleChange}
                  title="Business Phone (Fixed Line)"
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.mobilePhone}
                  errorText={errors.mobilePhone}
                  errorTouched={touched.mobilePhone}
                  name="mobilePhone"
                  onChange={handleChange}
                  title="Mobile Phone"
                  required
                />
                <Input
                  className={styles.inputWrapper}
                  value={values.email}
                  errorText={errors.email}
                  errorTouched={touched.email}
                  name="email"
                  onChange={handleChange}
                  title="Email"
                  required
                />
              </div>

              <div>
                <div className={styles.divider} />
                <div className={styles.subTitle}>Remarks</div>
              </div>
              <div className={styles.blockWrapper}>
                <Textarea
                  value={values.remarks}
                  name="remarks"
                  onChange={handleChange}
                  title="Personal Remarks"
                />
              </div>

              <div>
                <div className={styles.divider} />
              </div>
              <div className={styles.buttonsWrapper}>
                <div className={styles.buttonWrapper}>
                  <Button size="medium" onClick={handleSubmit} type="danger">
                    ABORT
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button size="medium" onClick={handleSubmit} type="primary">
                    SAVE
                  </Button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddUser;
