import React, { useEffect, useState } from 'react';
import styles from './votesManagement.module.scss';
import Arrow from '../../../assets/icons/caret.svg';
import {
  useAsyncDebounce,
  useBlockLayout,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import Search from '../../../assets/icons/search.svg';
import Button from '../../../components/Button';
import routes from '../../../routing/config';
import Trash from '../../../assets/icons/trash.svg';
import Order from '../../../assets/icons/order.svg';
import Edit from '../../../assets/icons/edit.svg';
import ActiveCheckbox from '../../../assets/img/active-check.svg';
import InactiveCheckbox from '../../../assets/img/inactive-checkbox.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../../api';
import ModalDialog from '../../../components/ModalDialog';
import Clear from '../../../assets/icons/clear.svg';
import Vote from '../../../interfaces/vote.interface';

const VotesManagement: React.FC = (): JSX.Element => {
  const history = useNavigate();
  const [data, setData] = useState([] as Vote[]);

  const deleteVote = async (id: number) => {
    await api.votes.deleteVote(id);
    getVotes();
  };
  const dispatch = useDispatch();

  const getVotes = async () => {
    const votes = await api.votes.getVotesList();
    setData([...votes]);
  };

  useEffect(() => {
    getVotes();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 200,
      },
      {
        Header: 'Result',
        accessor: 'result',
        width: 420,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        width: 220,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        width: 220,
      },
      {
        Header: '',
        accessor: 'delete',
        width: 40,
      },
      {
        Header: '',
        accessor: 'edit',
        width: 40,
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      //@ts-ignore
      columns,
      data,
      initialState: {
        //@ts-ignore
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  }: any = tableInstance;

  const GlobalFilter = ({ globalFilter, setGlobalFilter }: any) => {
    const [value, setValue] = React.useState(globalFilter);
    const search = useAsyncDebounce(() => {
      setGlobalFilter(value || undefined);
    }, 200);

    const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        search();
      }
    };

    return (
      <div className={styles.searchContainer}>
        <input
          className={styles.searchInput}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={`Search`}
        />
        <div onClick={() => setGlobalFilter('')} className={styles.clearButton}>
          <img className={styles.searchIcon} src={Clear} alt="clear" />
        </div>
        <div onClick={search} className={styles.searchButton}>
          <img className={styles.searchIcon} src={Search} alt="search" />
        </div>
      </div>
    );
  };

  const CustomCell = ({ cell }: any) => {
    if (typeof cell.value === 'boolean') {
      return (
        <td {...cell.getCellProps()}>
          <div style={{ marginLeft: 10 }}>
            <img
              src={cell.value ? ActiveCheckbox : InactiveCheckbox}
              alt="inactive"
            />
          </div>
        </td>
      );
    }

    if (cell.column.id === 'result') {
      const result = cell.row.original.result;
      const resultArray = [];
      for (let key in result) {
        if (key !== 'total') {
          const percent =
            (result[key].screenTime / result.total.screenTime +
              result[key].quantity / result.total.quantity) /
            2;
          resultArray.push(`${key} ${Math.round(percent * 100)}%`);
        }
      }
      return <td {...cell.getCellProps()}>{resultArray.join(' vs ')}</td>;
    }

    if (
      cell.column.id === 'delete' ||
      cell.column.id === 'market' ||
      cell.column.id === 'edit'
    ) {
      return (
        <td {...cell.getCellProps()}>
          {cell.column.Header}
          {cell.column.id === 'delete' && (
            <ModalDialog
              text="Are you sure?"
              onSubmit={() => {
                console.log('cell', cell);
                deleteVote(cell?.row?.original?.id);
              }}
            >
              <img
                style={{ cursor: 'pointer' }}
                src={Trash}
                onClick={() => {}}
              />
            </ModalDialog>
          )}
          {cell.column.id === 'market' && <img src={Order} />}
          {cell.column.id === 'edit' && (
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history(routes.addVote.route, {
                  state: {
                    vote: cell?.row?.original,
                  },
                });
              }}
              src={Edit}
            />
          )}
        </td>
      );
    }
    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
  };

  return (
    <div>
      <div className={styles.headerBlock}>
        <div className={styles.title}>Votes Management</div>
        <div className={styles.dFlex}>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              history(routes.addVote.route);
            }}
          >
            + ADD
          </Button>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className={styles.borderSpacingDiv}>
        <table className={styles.borderSpacing} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={styles.tableHeader}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    className={styles.tableColumnWrapper}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Arrow}
                            className={styles.arrowBottom}
                            alt="expanded"
                          />
                        ) : (
                          <img
                            src={Arrow}
                            className={styles.arrowTop}
                            alt="expanded"
                          />
                        )
                      ) : (
                        <span className={styles.emptyArrow} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.tableRows} {...getTableBodyProps()}>
            {rows.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <tr className={styles.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return <CustomCell cell={cell} />;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default VotesManagement;
