import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './Layout';
import Routes from '../routing/Routes';
import Login from './Login';
import MetamaskProvider from '../components/MetamaskProvider/';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';

function getLibrary(provider: any) {
  return new Web3(provider);
}

export default () => {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetamaskProvider>
        <BrowserRouter>
          {loggedIn ? (
            <Layout>
              <Routes />
            </Layout>
          ) : (
            <Login setLoggedIn={setLoggedIn} />
          )}
        </BrowserRouter>
      </MetamaskProvider>
    </Web3ReactProvider>
  );
};
