export default {
  login: {
    route: '/',
  },
  logout: {
    route: '/logout',
  },
  addCollection: {
    route: '/collections/add',
  },
  editCollection: {
    route: '/collections/edit',
  },
  collectionsList: {
    route: '/collections/list',
  },
  editNFT: {
    route: '/nft/edit',
  },
  systemSettings: {
    route: '/settings/system',
  },
  usersManagement: {
    route: '/settings/users',
  },
  addUser: {
    route: '/settings/users/add',
  },
  systemAccessLog: {
    route: '/logs/access',
  },
  mintingLog: {
    route: '/logs/minting',
  },
  financialLog: {
    route: '/logs/financial',
  },
  textEditor: {
    route: '/editor/text',
  },
  mediaEditor: {
    route: '/editor/media',
  },
  votesManagement: {
    route: '/votes',
  },
  addVote: {
    route: '/votes/add',
  },
} as const;
