import React, { useState } from 'react';
import styles from './menu.module.scss';
import menuIcon from '../../assets/icons/menu.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from '../../routing/config';
import { useSelector } from 'react-redux';
import GlobalState from '../../store/reducers/globalState.interface';

export default ({}: {}): JSX.Element => {
  const [menuOpenStatus, setMenuOpenStatus] = useState(true);
  const users = useSelector(
    (state: GlobalState) => state.user.user.permissionMode,
  );

  const history = useNavigate();

  const location = useLocation();
  return (
    <div
      style={{ width: menuOpenStatus ? 300 : 50 }}
      className={styles.sideMenu}
    >
      <div
        className={styles.menuIcon}
        onClick={() => {
          setMenuOpenStatus(!menuOpenStatus);
        }}
      >
        <img src={menuIcon} alt="menu" />
      </div>
      {menuOpenStatus && (
        <div className={styles.menuWrapper}>
          <div className={styles.whiteItem}>Collections</div>
          <div
            onClick={() => {
              history(routes.collectionsList.route);
            }}
            className={`${styles.subItem} ${
              location.pathname === routes.collectionsList.route
                ? styles.activeItem
                : ''
            }`}
          >
            List Collections & NFTs
          </div>

          <div
            onClick={() => {
              history(routes.votesManagement.route);
            }}
            className={`${styles.subItem} ${
              location.pathname === routes.votesManagement.route
                ? styles.activeItem
                : ''
            }`}
          >
            Votes
          </div>

          {(users === 'superadmin' || users === 'admin') && (
            <>
              <div className={styles.whiteItem}>Logs & Statistics</div>
              <div
                onClick={() => {
                  history(routes.systemAccessLog.route);
                }}
                className={`${styles.subItem} ${
                  location.pathname === routes.systemAccessLog.route
                    ? styles.activeItem
                    : ''
                }`}
              >
                System Access Logs
              </div>
              <div
                onClick={() => {
                  history(routes.mintingLog.route);
                }}
                className={`${styles.subItem} ${
                  location.pathname === routes.mintingLog.route
                    ? styles.activeItem
                    : ''
                }`}
              >
                Minting Log
              </div>
            </>
          )}
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    history.push(routes.financialLog.route);*/}
          {/*  }}*/}
          {/*  className={`${styles.subItem} ${*/}
          {/*    location.pathname === routes.financialLog.route*/}
          {/*      ? styles.activeItem*/}
          {/*      : ''*/}
          {/*  }`}*/}
          {/*>*/}
          {/*  Financial Overview*/}
          {/*</div>*/}
          {users === 'superadmin' && (
            <>
              <div className={styles.whiteItem}>System Settings</div>
              <div
                onClick={() => {
                  history(routes.systemSettings.route);
                }}
                className={`${styles.subItem} ${
                  location.pathname === routes.systemSettings.route
                    ? styles.activeItem
                    : ''
                }`}
              >
                General System Settings
              </div>
              <div
                onClick={() => {
                  history(routes.usersManagement.route);
                }}
                className={`${styles.subItem} ${
                  location.pathname === routes.usersManagement.route
                    ? styles.activeItem
                    : ''
                }`}
              >
                Users Management
              </div>
            </>
          )}
          {(users === 'superadmin' || users === 'admin') && (
            <>
              <div className={styles.whiteItem}>Webpage Editor</div>
              <div
                onClick={() => {
                  history(routes.textEditor.route);
                }}
                className={`${styles.subItem} ${
                  location.pathname === routes.textEditor.route
                    ? styles.activeItem
                    : ''
                }`}
              >
                Text
              </div>
              <div
                onClick={() => {
                  history(routes.mediaEditor.route);
                }}
                className={`${styles.subItem} ${
                  location.pathname === routes.mediaEditor.route
                    ? styles.activeItem
                    : ''
                }`}
              >
                Media
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
