enum UsersActions {
  SET_USERS_LOADING = 'SET_USERS_LOADING',
  GET_USERS_LIST = 'GET_USERS_LIST',
  GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
}

export default UsersActions;
