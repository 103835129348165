import { call, put, takeEvery } from 'redux-saga/effects';
import UsersActions from '../actions/users/usersActions.enum';
import GetUsersListAction from '../actions/users/interfaces/getUsersListAction.interface';
import api from '../../api';
import { getUsersListSuccess } from '../actions/users';
import User from '../../interfaces/user.interface';

function* getUsersRequestWorker() {
  try {
    const responseData: User[] = yield call(api.users.getUsersList);

    yield put(getUsersListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }
}

export default function* usersWatcher() {
  yield takeEvery<GetUsersListAction>(
    UsersActions.GET_USERS_LIST,
    getUsersRequestWorker,
  );
}
