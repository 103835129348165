import GetDevicesListSuccessAction from './interfaces/getDevicesListSuccessAction.interface';
import GetDevicesListAction from './interfaces/getDevicesListAction.interface';
import DevicesActions from './devicesActions.enum';

export const getDevicesList = (
  payload: GetDevicesListAction['payload'],
): GetDevicesListAction => ({
  type: DevicesActions.GET_DEVICES_LIST,
  payload,
});

export const getDevicesListSuccess = (
  payload: GetDevicesListSuccessAction['payload'],
): GetDevicesListSuccessAction => ({
  type: DevicesActions.GET_DEVICES_LIST_SUCCESS,
  payload,
});
