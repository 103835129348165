import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'toastify-js/src/toastify.css';
import { Provider } from 'react-redux';
import App from './pages';
import { store, persistor } from './store';
import { InjectedConnector } from '@web3-react/injected-connector';
import { PersistGate } from 'redux-persist/integration/react';

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
