import React, { Component, useState } from 'react';
import styles from './text.module.scss';

const Text: React.FC = (): JSX.Element => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Text Editor</div>
    </div>
  );
};
export default Text;
