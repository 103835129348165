import axios from 'axios';
import { API_URL } from '../../../constants/api';
import DevicesRoutes from './enums/devicesRoutes.enum';
import GetDevicesListRequestData from './interfaces/getDevicesListRequestData.interface';
import GetDevicesListResponseData from './interfaces/getDevicesListResponseData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';

export default {
  /**
   * Retrieves the devices list
   * @param requestData
   */
  getDevicesList: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.Devices}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
};
